import { writable } from 'svelte/store';

export type TabId = 'overview' | 'art' | 'bounties' | 'members' | 'resources' | 'settings';

interface NavigationState {
  activeTab: TabId;
  previousTab: TabId | null;
  navigationHistory: TabId[];
  isTransitioning: boolean;
}

function createNavigationStore() {
  const { subscribe, set, update } = writable<NavigationState>({
    activeTab: 'overview',
    previousTab: null,
    navigationHistory: ['overview'],
    isTransitioning: false
  });

  return {
    subscribe,
    
    setTab: (tabId: TabId) => {
      update(state => ({
        ...state,
        previousTab: state.activeTab,
        activeTab: tabId,
        navigationHistory: [...state.navigationHistory, tabId],
        isTransitioning: true
      }));

      // Reset transition state
      setTimeout(() => {
        update(state => ({
          ...state,
          isTransitioning: false
        }));
      }, 300);
    },

    goBack: () => {
      update(state => {
        if (state.navigationHistory.length <= 1) return state;
        
        const newHistory = [...state.navigationHistory];
        newHistory.pop(); // Remove current
        const previousTab = newHistory[newHistory.length - 1] || 'overview';

        return {
          ...state,
          previousTab: state.activeTab,
          activeTab: previousTab,
          navigationHistory: newHistory,
          isTransitioning: true
        };
      });
    },

    reset: () => {
      set({
        activeTab: 'overview',
        previousTab: null,
        navigationHistory: ['overview'],
        isTransitioning: false
      });
    }
  };
}

export const navigationStore = createNavigationStore();