<script lang="ts">
  import { bountyStore } from '$lib/stores/bounties';
  import type { BountyFilter } from '$lib/types';
  import { Shield, Target, Clock, Coins } from 'lucide-svelte';

  const difficulties = ['beginner', 'intermediate', 'advanced'];
  const statuses = ['open', 'in_progress', 'completed'];
  const rewardRanges = [
    { label: 'Under 100', min: 0, max: 100 },
    { label: '100-500', min: 100, max: 500 },
    { label: '500-1000', min: 500, max: 1000 },
    { label: '1000+', min: 1000, max: null }
  ];
  const timeframes = [
    { label: '< 24 hours', hours: 24 },
    { label: '< 3 days', hours: 72 },
    { label: '< 1 week', hours: 168 },
    { label: 'Any time', hours: null }
  ];

  let filters: BountyFilter = {
    difficulty: [],
    status: [],
    rewardRange: null,
    timeframe: null
  };

  function updateFilters() {
    bountyStore.setFilters(filters);
  }

  function resetFilters() {
    filters = {
      difficulty: [],
      status: [],
      rewardRange: null,
      timeframe: null
    };
    updateFilters();
  }
</script>

<div class="card space-y-6">
  <div class="flex items-center justify-between">
    <h2 class="text-xl font-bold">Filters</h2>
    <button 
      class="text-sm text-turquoise hover:text-turquoise/80 transition-colors"
      on:click={resetFilters}
    >
      Reset
    </button>
  </div>

  <!-- Difficulty -->
  <div>
    <h3 class="text-sm text-white/60 mb-3 flex items-center">
      <Target size={16} class="mr-2" />
      Difficulty
    </h3>
    <div class="space-y-2">
      {#each difficulties as level}
        <label class="flex items-center space-x-2 cursor-pointer">
          <input
            type="checkbox"
            bind:group={filters.difficulty}
            value={level}
            class="form-checkbox"
            on:change={updateFilters}
          />
          <span class="capitalize">{level}</span>
        </label>
      {/each}
    </div>
  </div>

  <!-- Status -->
  <div>
    <h3 class="text-sm text-white/60 mb-3 flex items-center">
      <Shield size={16} class="mr-2" />
      Status
    </h3>
    <div class="space-y-2">
      {#each statuses as status}
        <label class="flex items-center space-x-2 cursor-pointer">
          <input
            type="checkbox"
            bind:group={filters.status}
            value={status}
            class="form-checkbox"
            on:change={updateFilters}
          />
          <span class="capitalize">{status.replace('_', ' ')}</span>
        </label>
      {/each}
    </div>
  </div>

  <!-- Reward Range -->
  <div>
    <h3 class="text-sm text-white/60 mb-3 flex items-center">
      <Coins size={16} class="mr-2" />
      Reward Range
    </h3>
    <div class="space-y-2">
      {#each rewardRanges as range}
        <label class="flex items-center space-x-2 cursor-pointer">
          <input
            type="radio"
            bind:group={filters.rewardRange}
            value={range}
            class="form-radio"
            on:change={updateFilters}
          />
          <span>{range.label} DREAMS</span>
        </label>
      {/each}
    </div>
  </div>

  <!-- Timeframe -->
  <div>
    <h3 class="text-sm text-white/60 mb-3 flex items-center">
      <Clock size={16} class="mr-2" />
      Timeframe
    </h3>
    <div class="space-y-2">
      {#each timeframes as time}
        <label class="flex items-center space-x-2 cursor-pointer">
          <input
            type="radio"
            bind:group={filters.timeframe}
            value={time}
            class="form-radio"
            on:change={updateFilters}
          />
          <span>{time.label}</span>
        </label>
      {/each}
    </div>
  </div>
</div>