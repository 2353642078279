<script lang="ts">
  import { Code, Palette, Book, Sword, ChevronRight } from 'lucide-svelte';
  import type { Skill } from '$lib/types/skills';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let skill: Skill;
  export let isUnlocked = false;

  const skillIcons = {
    combat: Sword,
    design: Palette,
    lore: Book,
    technical: Code
  };

  const skillColors = {
    combat: 'text-turquoise',
    design: 'text-turquoise',
    lore: 'text-turquoise',
    technical: 'text-turquoise'
  };

  function handleClick() {
    if (isUnlocked) {
      dispatch('select', skill);
    }
  }
</script>

<button 
  class="dream-card group relative {isUnlocked ? 'hover:scale-105' : 'opacity-50'} 
         transition-all duration-300 w-full text-left"
  class:pointer-events-none={!isUnlocked}
  on:click={handleClick}
>
  <div class="flex items-start space-x-4">
    <div class="p-3 rounded-lg bg-surface/50 {skillColors[skill.type]}">
      <svelte:component this={skillIcons[skill.type]} size={24} />
    </div>
    <div class="flex-1">
      <div class="flex items-center justify-between">
        <h3 class="font-bold group-hover:text-turquoise transition-colors">
          {skill.name}
        </h3>
        {#if isUnlocked}
          <ChevronRight size={20} class="text-turquoise/50 group-hover:text-turquoise transition-colors" />
        {/if}
      </div>
      <p class="text-sm text-dream-ghost/60">{skill.description}</p>
      
      {#if skill.level}
        <div class="mt-4 space-y-2">
          <div class="flex justify-between text-sm">
            <span class="text-dream-ghost/60">Level {skill.level}</span>
            <span class="text-dream-ghost/40">{skill.xpRequired} XP required</span>
          </div>
          <div class="h-1 bg-surface rounded-full overflow-hidden">
            <div class="h-full bg-turquoise/50 w-0 group-hover:w-full transition-all duration-1000"></div>
          </div>
        </div>
      {/if}
    </div>
  </div>

  {#if !isUnlocked}
    <div class="absolute inset-0 bg-cod-gray/50 backdrop-blur-sm rounded-lg flex items-center justify-center">
      <div class="text-dream-ghost/60 text-sm">
        Unlock prerequisites first
      </div>
    </div>
  {/if}
</button>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }
</style>