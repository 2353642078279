<script lang="ts">
  import { Trophy, Target, Star } from 'lucide-svelte';

  const stats = {
    level: 15,
    xp: 2450,
    nextLevel: 3000,
    badges: [
      {
        name: 'Art Master',
        icon: 'https://api.dicebear.com/7.x/shapes/svg?seed=artmaster',
        description: 'Mastery in digital art creation'
      },
      {
        name: 'Story Weaver',
        icon: 'https://api.dicebear.com/7.x/shapes/svg?seed=storyweaver',
        description: 'Excellence in narrative crafting'
      },
      {
        name: 'Community Leader',
        icon: 'https://api.dicebear.com/7.x/shapes/svg?seed=leader',
        description: 'Outstanding community leadership'
      }
    ]
  };
</script>

<div class="space-y-6">
  <!-- Level Progress -->
  <div>
    <div class="flex items-center justify-between mb-2">
      <span class="text-dream-ghost/60">Level {stats.level}</span>
      <span class="text-sm text-dream-ghost/40">{stats.xp}/{stats.nextLevel} XP</span>
    </div>
    <div class="h-2 bg-surface rounded-full overflow-hidden">
      <div
        class="h-full bg-gradient-to-r from-turquoise to-turquoise/50 transition-all"
        style="width: {(stats.xp / stats.nextLevel) * 100}%"
      ></div>
    </div>
  </div>

  <!-- Badges -->
  <div>
    <h3 class="text-sm text-dream-ghost/60 mb-3">Recent Achievements</h3>
    <div class="grid grid-cols-3 gap-4">
      {#each stats.badges as badge}
        <div class="dream-card bg-surface/50 group hover:scale-105 transition-all">
          <div class="flex flex-col items-center text-center">
            <div class="w-12 h-12 rounded-lg bg-surface/30 p-2 mb-2 group-hover:bg-turquoise/10 transition-colors">
              <img
                src={badge.icon}
                alt={badge.name}
                class="w-full h-full object-contain"
              />
            </div>
            <span class="text-xs font-medium text-dream-ghost/60 group-hover:text-turquoise transition-colors">
              {badge.name}
            </span>
            <span class="text-[10px] text-dream-ghost/40 mt-1 hidden group-hover:block">
              {badge.description}
            </span>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-4
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }
</style>