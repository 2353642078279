<script lang="ts">
  import { fade } from 'svelte/transition';
  import type { TabId } from '../../stores/navigation';
  import { navigationStore } from '../../stores/navigation';

  export let tabs: Array<{
    id: TabId;
    label: string;
    icon: any;
  }>;

  export let activeTab: string;

  function handleTabClick(tabId: TabId) {
    activeTab = tabId;
    navigationStore.setTab(tabId);
  }
</script>

<div class="border-b border-dream-pink/10">
  <div class="flex space-x-8">
    {#each tabs as tab}
      <button
        class="flex items-center space-x-2 py-4 border-b-2 transition-colors relative group
          {activeTab === tab.id ? 'border-dream-pink text-dream-pink' : 'border-transparent text-dream-ghost/40 hover:text-dream-ghost/60'}"
        on:click={() => handleTabClick(tab.id)}
      >
        <svelte:component 
          this={tab.icon} 
          size={20}
          class="transform transition-transform group-hover:scale-110 duration-300"
        />
        <span class="font-medium">{tab.label}</span>

        {#if activeTab === tab.id}
          <div 
            class="absolute inset-0 bg-dream-pink/5 rounded-lg -z-10"
            in:fade={{ duration: 200 }}
          ></div>
        {/if}
      </button>
    {/each}
  </div>
</div>