import { writable, get } from 'svelte/store';
import { showSuccess } from '../utils/toast';
import { push } from 'svelte-spa-router';
import type { Web3Provider } from '../services/wallet';

interface AuthState {
  web3Provider: Web3Provider | null;
}

function createAuthStore() {
  const { subscribe, set, update } = writable<AuthState | null>(null);

  return {
    subscribe,
    
    login: (session: Web3Provider) => {
      const state: AuthState = { web3Provider: session };
      set(state);
      localStorage.setItem('dreamdeck_session', JSON.stringify({ 
        address: session.address 
      }));
      showSuccess('Successfully connected!');
      push('/dashboard');
    },

    logout: () => {
      set(null);
      localStorage.removeItem('dreamdeck_session');
      push('/');
    },

    restoreSession: async () => {
      const saved = localStorage.getItem('dreamdeck_session');
      if (saved) {
        try {
          const session = JSON.parse(saved);
          if (session?.address) {
            // Note: Full session restoration would require re-connecting wallet
            localStorage.removeItem('dreamdeck_session');
          }
        } catch {
          localStorage.removeItem('dreamdeck_session');
        }
      }
    },

    // Add a method to get current state
    getState: () => get(authStore),

    // Method to get current wallet address
    getAddress: () => {
      const state = get(authStore);
      return state?.web3Provider?.address || null;
    }
  };
}

export const authStore = createAuthStore();