<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { Shield, Users, Star } from 'lucide-svelte';
  
  export let banner = {
    type: 'gradient',
    content: 'from-turquoise via-turquoise/50 to-transparent',
    height: 'h-64',
    overlayEffects: true
  };
  
  export let profile = {
    logo: '',
    name: '',
    description: '',
    stats: {
      members: 0,
      bounties: 0,
      rating: 0
    }
  };

  let scrollY: number;
  let headerElement: HTMLElement;
  let parallaxOffset = 0;

  function handleScroll() {
    if (headerElement) {
      const rect = headerElement.getBoundingClientRect();
      if (rect.top >= 0) {
        parallaxOffset = scrollY * 0.5;
      }
    }
  }
</script>

<svelte:window bind:scrollY on:scroll={handleScroll} />

<div 
  class="relative overflow-hidden mb-8"
  bind:this={headerElement}
  in:fly={{ y: 20, duration: 400 }}
>
  <!-- Banner -->
  <div 
    class="relative {banner.height}"
    style="transform: translateY({parallaxOffset}px)"
  >
    {#if banner.type === 'image'}
      <div class="w-full h-full relative">
        <img
          src={banner.content}
          alt="Realm Banner"
          class="w-full h-full object-cover"
        />
        <!-- Enhanced overlay for better text visibility -->
        <div class="absolute inset-0 bg-gradient-to-t from-cod-gray via-cod-gray/60 to-transparent"></div>
      </div>
    {:else}
      <div class="w-full h-full bg-gradient-to-r {banner.content}"></div>
    {/if}

    {#if banner.overlayEffects}
      <div class="absolute inset-0 bg-scanlines opacity-10"></div>
    {/if}
  </div>

  <!-- Profile Section -->
  <div class="max-w-7xl mx-auto px-8">
    <div class="card -mt-32 relative z-10">
      <div class="flex items-center space-x-8">
        <!-- Logo -->
        <div 
          class="w-32 h-32 rounded-lg bg-surface p-4 border-2 border-turquoise/20 shadow-glow relative overflow-hidden group"
          in:fade={{ duration: 300, delay: 200 }}
        >
          <img
            src={profile.logo}
            alt={profile.name}
            class="w-full h-full object-contain rounded-lg transform group-hover:scale-110 transition-transform duration-300"
          />
          <div class="absolute inset-0 bg-scanlines opacity-10"></div>
        </div>

        <!-- Info -->
        <div class="flex-1">
          <h1 
            class="text-4xl font-bold mb-2 text-turquoise"
            in:fade={{ duration: 300, delay: 300 }}
          >
            {profile.name}
          </h1>
          <p 
            class="text-white/60 max-w-2xl"
            in:fade={{ duration: 300, delay: 400 }}
          >
            {profile.description}
          </p>
        </div>
      </div>

      <!-- Stats -->
      <div 
        class="grid grid-cols-3 gap-4 mt-8"
        in:fade={{ duration: 300, delay: 500 }}
      >
        <div class="card card-hover">
          <div class="flex items-center space-x-3">
            <Users size={24} class="text-turquoise" />
            <div>
              <p class="text-sm text-white/60">Members</p>
              <p class="text-2xl font-bold">{profile.stats.members}</p>
            </div>
          </div>
        </div>

        <div class="card card-hover">
          <div class="flex items-center space-x-3">
            <Shield size={24} class="text-turquoise" />
            <div>
              <p class="text-sm text-white/60">Active Bounties</p>
              <p class="text-2xl font-bold">{profile.stats.bounties}</p>
            </div>
          </div>
        </div>

        <div class="card card-hover">
          <div class="flex items-center space-x-3">
            <Star size={24} class="text-turquoise" />
            <div>
              <p class="text-sm text-white/60">Rating</p>
              <p class="text-2xl font-bold">{profile.stats.rating}/5</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }

  .bg-scanlines {
    background-image: repeating-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0.1) 1px,
      transparent 1px,
      transparent 2px
    );
  }
</style>