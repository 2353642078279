<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Clock, Award, Tag, ChevronRight } from 'lucide-svelte';
  import type { Bounty } from '../../types';
  import { formatTimeLeft } from '../../utils/date';

  export let bounty: Bounty;
  let expanded = false;

  const difficultyColors = {
    beginner: 'text-turquoise',
    intermediate: 'text-turquoise',
    advanced: 'text-turquoise'
  };

  const statusColors = {
    open: 'text-turquoise',
    in_progress: 'text-turquoise',
    review: 'text-turquoise',
    completed: 'text-white/40',
    expired: 'text-white/40'
  };

  function handleClick() {
    expanded = !expanded;
  }
</script>

<div 
  class="card group hover:scale-[1.01] transition-all duration-300 cursor-pointer"
  on:click={handleClick}
  in:fade
>
  <div class="flex justify-between items-start mb-4">
    <div>
      <h3 class="text-xl font-bold mb-2 group-hover:text-turquoise transition-colors">
        {bounty.title}
      </h3>
      <p class="text-white/60 text-sm line-clamp-2">
        {bounty.description}
      </p>
    </div>
    <div class="flex flex-col items-end">
      <span class="text-sm {statusColors[bounty.status]} capitalize mb-2">
        {bounty.status.replace('_', ' ')}
      </span>
      <span class="text-sm {difficultyColors[bounty.difficulty]} capitalize">
        {bounty.difficulty}
      </span>
    </div>
  </div>

  <div class="flex items-center justify-between text-sm text-white/40">
    <div class="flex items-center space-x-4">
      <div class="flex items-center">
        <Award size={16} class="mr-1" />
        <span>{bounty.rewards.tokens} DREAM</span>
      </div>
      <div class="flex items-center">
        <Clock size={16} class="mr-1" />
        <span>{formatTimeLeft(bounty.deadline)}</span>
      </div>
    </div>
    <ChevronRight 
      size={20} 
      class="transform transition-transform duration-300 {expanded ? 'rotate-90' : ''}"
    />
  </div>

  {#if expanded}
    <div 
      class="mt-6 pt-6 border-t border-turquoise/10"
      transition:fade={{ duration: 200 }}
    >
      <div class="space-y-4">
        <div>
          <h4 class="font-bold mb-2">Requirements</h4>
          <ul class="list-disc list-inside text-sm text-white/60 space-y-1">
            {#each bounty.requirements as requirement}
              <li>{requirement}</li>
            {/each}
          </ul>
        </div>

        <div>
          <h4 class="font-bold mb-2">Tags</h4>
          <div class="flex flex-wrap gap-2">
            {#each bounty.tags as tag}
              <span class="px-2 py-1 text-xs rounded-full bg-surface-light text-white/60 flex items-center">
                <Tag size={12} class="mr-1" />
                {tag}
              </span>
            {/each}
          </div>
        </div>

        <div class="flex justify-end">
          <a 
            href={`/bounties/${bounty.id}`}
            class="btn"
          >
            View Details
          </a>
        </div>
      </div>
    </div>
  {/if}
</div>