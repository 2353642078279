<script lang="ts">
  import { onMount } from 'svelte';
  import Router from 'svelte-spa-router';
  import { routes } from './lib/routes';
  import { push } from 'svelte-spa-router';
  import { Toaster } from 'svelte-french-toast';
  import { DotBackground } from './lib/components/ui/GridAndDotBackgrounds';
  import { authStore } from './lib/stores/auth';
  import Sidebar from './lib/components/Layout/Sidebar.svelte';
  import Logo from './lib/components/ui/Logo.svelte';
  import './app.css';

  let isAuthenticated = false;

  onMount(async () => {
    // Force dark theme
    document.documentElement.setAttribute('data-theme', 'dark');
    
    // Restore auth session
    authStore.restoreSession();

    // Handle initial navigation
    const hash = window.location.hash;
    if (hash === '#/' || hash === '') {
      push('/');
    }
  });

  // Subscribe to auth state
  authStore.subscribe(state => {
    isAuthenticated = !!state;
  });

  function handleEnterSite() {
    authStore.login();
  }
</script>

<div class="min-h-screen bg-cod-gray text-white">
  <DotBackground>
    {#if !isAuthenticated}
      <!-- Navigation Bar -->
      <nav class="fixed top-0 left-0 right-0 z-50 bg-surface/80 backdrop-blur-sm border-b border-turquoise/10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex items-center justify-between">
          <Logo variant="turquoise" size="md" />
          <div class="flex items-center space-x-8">
            <a href="#/wiki" class="text-white/60 hover:text-turquoise transition-colors">Docs</a>
            <button class="dream-button" on:click={handleEnterSite}>
              Get Started
            </button>
          </div>
        </div>
      </nav>

      <!-- Main Content with Padding for Nav -->
      <main class="pt-20">
        <Router {routes} />
      </main>
    {:else}
      <div class="flex min-h-screen">
        <!-- Sidebar -->
        <Sidebar />
        
        <!-- Main Content -->
        <main class="flex-1 ml-20 transition-all duration-300">
          <div class="max-w-full h-full">
            <Router {routes} />
          </div>
        </main>
      </div>
    {/if}

    <Toaster position="top-right" />
  </DotBackground>
</div>

<style>
  :global(html, body) {
    @apply min-h-screen overflow-x-hidden;
    background: theme('colors.cod-gray');
    color-scheme: dark;
  }

  main {
    width: calc(100% - 5rem);
    max-width: 100%;
  }
</style>