<script lang="ts">
  import { Settings as SettingsIcon, User, Shield, Bell, Wallet } from 'lucide-svelte';
  import { onMount } from 'svelte';
  import { authStore } from '../../lib/stores/auth';

  let user = null;
  
  authStore.subscribe(value => {
    user = value;
    if (!value) {
      window.location.href = '/';
    }
  });

  onMount(() => {
    authStore.restoreSession();
  });

  const settingsSections = [
    {
      icon: User,
      title: 'Profile Settings',
      description: 'Manage your profile information and preferences'
    },
    {
      icon: Shield,
      title: 'Security',
      description: 'Configure your account security settings'
    },
    {
      icon: Bell,
      title: 'Notifications',
      description: 'Control how you receive updates and alerts'
    },
    {
      icon: Wallet,
      title: 'Wallet & Payments',
      description: 'Manage your connected wallets and payment methods'
    }
  ];
</script>

<div class="py-6">
  <div class="mb-8">
    <h1 class="text-4xl font-bold mb-2 bg-gradient-to-r from-dream-pink to-dream-teal bg-clip-text text-transparent">
      Settings
    </h1>
    <p class="text-dream-ghost/60">Customize your DREAMDECK experience</p>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
    {#each settingsSections as section}
      <div class="dream-card group hover:translate-y-[-2px]">
        <div class="flex items-start space-x-4">
          <div class="p-3 rounded-lg bg-dream-surface group-hover:bg-dream-pink/10 transition-colors">
            <svelte:component this={section.icon} size={24} class="text-dream-pink" />
          </div>
          <div>
            <h3 class="text-xl font-space mb-2 group-hover:text-dream-pink transition-colors">
              {section.title}
            </h3>
            <p class="text-dream-ghost/60 text-sm">{section.description}</p>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>