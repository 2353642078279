<script lang="ts">
  import { onMount } from 'svelte';
  import { Plus, Filter, Search } from 'lucide-svelte';
  import type { Realm } from '../../types';
  import { bountyStore } from '../../stores/bounties';
  import BountyCard from '../Bounty/BountyCard.svelte';

  export let realm: Realm;
  let searchQuery = '';

  onMount(() => {
    bountyStore.loadBounties(realm.id);
  });

  $: bounties = $bountyStore.bounties;
  $: loading = $bountyStore.loading;
</script>

<div class="space-y-6">
  <div class="flex justify-between items-center">
    <h2 class="text-2xl font-bold">Active Bounties</h2>
    <div class="flex space-x-4">
      <div class="relative">
        <input
          type="text"
          bind:value={searchQuery}
          placeholder="Search bounties..."
          class="dream-input pl-10"
        />
        <Search size={18} class="absolute left-3 top-1/2 -translate-y-1/2 text-dream-ghost/40" />
      </div>
      <button class="dream-button bg-dream-surface">
        <Filter size={20} class="mr-2" />
        Filter
      </button>
      <button class="dream-button">
        <Plus size={20} class="mr-2" />
        Create Bounty
      </button>
    </div>
  </div>

  {#if loading}
    <div class="space-y-4">
      {#each Array(3) as _}
        <div class="dream-card animate-pulse">
          <div class="h-6 bg-dream-surface/50 rounded w-1/3 mb-4"></div>
          <div class="h-4 bg-dream-surface/50 rounded w-2/3 mb-2"></div>
          <div class="h-4 bg-dream-surface/50 rounded w-1/2"></div>
        </div>
      {/each}
    </div>
  {:else if bounties.length === 0}
    <div class="dream-card bg-dream-surface/50 text-center py-12">
      <p class="text-dream-ghost/60 mb-4">No active bounties available</p>
      <button class="dream-button">
        <Plus size={20} class="mr-2" />
        Create First Bounty
      </button>
    </div>
  {:else}
    <div class="space-y-4">
      {#each bounties as bounty}
        <BountyCard {bounty} />
      {/each}
    </div>
  {/if}
</div>