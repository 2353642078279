import { showError, showSuccess } from '../utils/toast';
import type { Database } from '../types/database';
import type { Bounty } from '../types';
import openai from './openai';

export interface StoryDraft extends Database['public']['Tables']['story_drafts']['Row'] {}

export interface StoryResponse {
  content: string;
  loreReferences?: Array<{
    title: string;
    description: string;
  }>;
  suggestions?: string[];
}

export async function generateStoryContent(prompt: string, bounty: Bounty): Promise<StoryResponse> {
  try {
    // Create a thread with the user's prompt
    const thread = await openai.beta.threads.create({
      messages: [
        {
          role: 'user',
          content: `Bounty: ${bounty.title}\n\nPrompt: ${prompt}\n\nRequirements:\n${bounty.requirements.join('\n')}`
        }
      ]
    });

    // Run the assistant
    const run = await openai.beta.threads.runs.create(
      thread.id,
      {
        assistant_id: bounty.aiAssistant || 'g-673b571f717c8191b04493e2af3632d3-mbs-lore-assistant',
        instructions: `Generate story content based on the provided bounty and prompt. 
                      Ensure the story aligns with Mindborn Sons lore and meets all requirements.
                      Include relevant lore references and writing suggestions.`
      }
    );

    // Wait for completion
    let response;
    while (true) {
      const runStatus = await openai.beta.threads.runs.retrieve(
        thread.id,
        run.id
      );

      if (runStatus.status === 'completed') {
        const messages = await openai.beta.threads.messages.list(thread.id);
        response = messages.data[0].content[0];
        break;
      } else if (runStatus.status === 'failed') {
        throw new Error('Failed to generate content');
      }

      // Wait before checking again
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    // Parse the response
    const content = response.text.content;
    
    // Extract lore references and suggestions from the content
    // This is a simplified example - you might want to add more structure to the assistant's output
    const loreReferences = [
      {
        title: 'The First Yaksha',
        description: 'Ancient tales speak of the first Yaksha who discovered the power of sun-forging...'
      },
      {
        title: 'Sun-Energy Mechanics',
        description: 'The fundamental principles of harnessing solar energy for metalworking...'
      }
    ];

    const suggestions = [
      'Consider expanding on the cultural impact of this discovery',
      'Add more sensory details about the forging process',
      'Include dialogue between master and apprentice'
    ];

    return {
      content,
      loreReferences,
      suggestions
    };
  } catch (error: any) {
    showError(error.message || 'Failed to generate content');
    throw error;
  }
}

export async function saveDraft(draft: Database['public']['Tables']['story_drafts']['Insert']) {
  try {
    const { data, error } = await supabase
      .from('story_drafts')
      .insert(draft)
      .select()
      .single();

    if (error) throw error;

    showSuccess('Draft saved successfully');
    return data;
  } catch (error: any) {
    showError(error.message || 'Failed to save draft');
    throw error;
  }
}

export async function getDrafts(bountyId: string): Promise<StoryDraft[]> {
  try {
    const { data, error } = await supabase
      .from('story_drafts')
      .select()
      .eq('bounty_id', bountyId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  } catch (error: any) {
    showError(error.message || 'Failed to load drafts');
    throw error;
  }
}

export async function updateDraft(
  id: string, 
  updates: Database['public']['Tables']['story_drafts']['Update']
) {
  try {
    const { data, error } = await supabase
      .from('story_drafts')
      .update(updates)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;

    showSuccess('Draft updated successfully');
    return data;
  } catch (error: any) {
    showError(error.message || 'Failed to update draft');
    throw error;
  }
}

export async function deleteDraft(id: string) {
  try {
    const { error } = await supabase
      .from('story_drafts')
      .delete()
      .eq('id', id);

    if (error) throw error;
    showSuccess('Draft deleted successfully');
  } catch (error: any) {
    showError(error.message || 'Failed to delete draft');
    throw error;
  }
}