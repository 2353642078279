<script lang="ts">
  import { onMount } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { Shield, Target, Users, Book, Settings, Image } from 'lucide-svelte';
  import RealmHeader from '$lib/components/Realm/RealmHeader.svelte';
  import RealmNavigation from '$lib/components/Realm/RealmNavigation.svelte';
  import RealmOverview from '$lib/components/Realm/RealmOverview.svelte';
  import RealmBounties from '$lib/components/Realm/RealmBounties.svelte';
  import RealmMembers from '$lib/components/Realm/RealmMembers.svelte';
  import RealmResources from '$lib/components/Realm/RealmResources.svelte';
  import RealmSettings from '$lib/components/Realm/RealmSettings.svelte';
  import ArtFeed from '$lib/components/CreationHub/ArtFeed.svelte';
  import type { Realm } from '$lib/types';
  import { realmStore } from '$lib/stores/realms';
  import { push } from 'svelte-spa-router';

  export let params: { id: string };

  let realm: Realm | null = null;
  let loading = true;
  let activeTab = 'overview';

  const tabs = [
    { id: 'overview', label: 'Overview', icon: Shield },
    { id: 'art', label: 'Art Gallery', icon: Image },
    { id: 'bounties', label: 'Bounties', icon: Target },
    { id: 'members', label: 'Members', icon: Users },
    { id: 'resources', label: 'Resources', icon: Book },
    { id: 'settings', label: 'Settings', icon: Settings }
  ];

  onMount(async () => {
    loading = true;
    try {
      realm = realmStore.getRealm(params.id);
      
      if (!realm) {
        await realmStore.loadRealms();
        realm = realmStore.getRealm(params.id);
        
        if (!realm) {
          push('/realms');
          return;
        }
      }
    } catch (error) {
      console.error('Failed to load realm:', error);
    } finally {
      loading = false;
    }
  });
</script>

<div class="min-h-screen bg-cod-gray">
  {#if loading}
    <div class="max-w-7xl mx-auto px-4 py-8">
      <div class="card animate-pulse">
        <div class="h-64 bg-surface-light rounded-lg mb-8"></div>
        <div class="h-8 bg-surface-light rounded w-1/3 mb-4"></div>
        <div class="h-4 bg-surface-light rounded w-2/3"></div>
      </div>
    </div>
  {:else if realm}
    <RealmHeader 
      banner={{
        type: 'image',
        content: realm.image,
        height: 'h-96',
        overlayEffects: true
      }}
      profile={{
        logo: realm.logo,
        name: realm.name,
        description: realm.description,
        stats: {
          members: realm.memberCount,
          bounties: realm.bountyCount,
          rating: realm.rating
        }
      }}
    />
    
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="card">
        <RealmNavigation {tabs} bind:activeTab />
        
        <!-- Content -->
        <div class="min-h-[400px] p-6">
          {#if activeTab === 'overview'}
            <RealmOverview {realm} />
          {:else if activeTab === 'art'}
            <ArtFeed realmId={realm.id} />
          {:else if activeTab === 'bounties'}
            <RealmBounties {realm} />
          {:else if activeTab === 'members'}
            <RealmMembers {realm} />
          {:else if activeTab === 'resources'}
            <RealmResources {realm} />
          {:else if activeTab === 'settings'}
            <RealmSettings {realm} />
          {/if}
        </div>
      </div>
    </div>
  {:else}
    <div class="max-w-7xl mx-auto px-4 py-8 text-center">
      <div class="card">
        <h1 class="text-2xl font-bold text-turquoise mb-4">Realm Not Found</h1>
        <p class="text-white/60 mb-6">This realm doesn't exist or you don't have access to it.</p>
        <button class="btn" on:click={() => push('/realms')}>
          Return to Realms
        </button>
      </div>
    </div>
  {/if}
</div>