import { OpenAI } from 'openai';
import { showError } from '../utils/toast';
import type { Skill } from '../types/skills';

// Initialize OpenAI client with proper error handling
const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Required for client-side usage
});

export interface LearningContent {
  syllabus: string[];
  resources: string[];
  exercises: Array<{
    title: string;
    description: string;
    difficulty: 'beginner' | 'intermediate' | 'advanced';
  }>;
  assessment: Array<{
    question: string;
    options: string[];
    correctAnswer: number;
  }>;
}

export async function generateLearningContent(skill: Skill): Promise<LearningContent | null> {
  if (!import.meta.env.VITE_OPENAI_API_KEY) {
    showError('OpenAI API key is not configured');
    return null;
  }

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: `You are an expert instructor for ${skill.type} skills in game development. 
                   Create a comprehensive learning plan for ${skill.name} at level ${skill.level}.`
        },
        {
          role: "user",
          content: `Generate a structured learning content including:
                   1. A detailed syllabus
                   2. Recommended learning resources
                   3. Practical exercises
                   4. Assessment questions
                   For: ${skill.description}`
        }
      ],
      temperature: 0.7,
      max_tokens: 2000
    });

    const content = response.choices[0].message?.content;
    if (!content) throw new Error('No content generated');

    // Parse the response into structured content
    return {
      syllabus: content.split('\n\n')[0].split('\n').filter(item => item.trim()),
      resources: content.split('\n\n')[1].split('\n').filter(item => item.trim()),
      exercises: [
        {
          title: 'Practice Exercise 1',
          description: content.split('\n\n')[2],
          difficulty: 'beginner'
        }
      ],
      assessment: [
        {
          question: 'What is the primary purpose of this skill?',
          options: ['Option A', 'Option B', 'Option C', 'Option D'],
          correctAnswer: 0
        }
      ]
    };
  } catch (error: any) {
    console.error('Learning content generation error:', error);
    showError(error?.message || 'Failed to generate learning content');
    return null;
  }
}