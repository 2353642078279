<script lang="ts">
  import { Home } from 'lucide-svelte';
</script>

<div class="flex flex-col items-center justify-center min-h-[60vh] text-center">
  <h1 class="text-6xl font-bold mb-4 bg-gradient-to-r from-dream-pink to-dream-teal bg-clip-text text-transparent">
    404
  </h1>
  <p class="text-xl text-dream-ghost/60 mb-8">This realm doesn't exist... yet.</p>
  <a href="/" class="dream-button flex items-center">
    <Home size={20} class="mr-2" />
    Return Home
  </a>
</div>