<script lang="ts">
  import { Heart, MessageSquare, Share2 } from 'lucide-svelte';

  const activities = [
    {
      id: 1,
      user: {
        name: 'Alex Chen',
        avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=alex',
      },
      content: 'Just completed a new character design for Mindborn Sons!',
      image: 'https://api.dicebear.com/7.x/shapes/svg?seed=character1',
      likes: 24,
      comments: 8,
      timestamp: '2h ago'
    },
    {
      id: 2,
      user: {
        name: 'Sarah Williams',
        avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=sarah',
      },
      content: 'Working on the new quest line for Cyber Realm',
      likes: 16,
      comments: 5,
      timestamp: '4h ago'
    }
  ];
</script>

<div class="space-y-4">
  {#each activities as activity}
    <div class="dream-card bg-surface/50" in:fade>
      <div class="flex items-start space-x-4">
        <img
          src={activity.user.avatar}
          alt={activity.user.name}
          class="w-10 h-10 rounded-full flex-shrink-0"
        />
        <div class="flex-1 min-w-0">
          <div class="flex items-center justify-between mb-2">
            <h3 class="font-bold hover:text-turquoise transition-colors truncate">
              {activity.user.name}
            </h3>
            <span class="text-sm text-dream-ghost/40 flex-shrink-0">{activity.timestamp}</span>
          </div>
          <p class="text-dream-ghost/80 mb-4 break-words">{activity.content}</p>
          
          {#if activity.image}
            <img
              src={activity.image}
              alt="Activity content"
              class="rounded-lg mb-4 w-full h-48 object-cover"
            />
          {/if}

          <div class="flex items-center space-x-6 text-sm text-dream-ghost/40">
            <button class="flex items-center space-x-2 hover:text-turquoise transition-colors">
              <Heart size={16} />
              <span>{activity.likes}</span>
            </button>
            <button class="flex items-center space-x-2 hover:text-turquoise transition-colors">
              <MessageSquare size={16} />
              <span>{activity.comments}</span>
            </button>
            <button class="flex items-center space-x-2 hover:text-turquoise transition-colors">
              <Share2 size={16} />
              <span>Share</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  {/each}
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-4
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }
</style>