<script lang="ts">
  import { Shield, Users, Book, Sword, Map } from 'lucide-svelte';
  import type { Realm } from '../../types';

  export let realm: Realm;
</script>

<div class="space-y-6">
  <!-- About Section -->
  <div class="dream-card">
    <div class="flex items-center space-x-3 mb-4">
      <div class="p-3 rounded-lg bg-surface/50 text-turquoise">
        <Map size={24} />
      </div>
      <h2 class="text-2xl font-bold">World Overview</h2>
    </div>
    <p class="text-dream-ghost/60">
      In the Second Epoch, as the Seven Sages guide humanity with ancient wisdom, 
      a mysterious army of metal warriors emerges, threatening to destroy everything. 
      Now, powerful factions vie for control while an ancient evil stirs beneath the surface.
    </p>
  </div>

  <!-- Features Grid -->
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div class="dream-card group hover:scale-105 transition-all duration-300">
      <Book size={20} class="text-turquoise mb-2" />
      <h3 class="font-bold mb-2 group-hover:text-turquoise transition-colors">Rich Lore</h3>
      <p class="text-sm text-dream-ghost/60">
        Deep mythology spanning epochs, gods, and ancient civilizations
      </p>
    </div>

    <div class="dream-card group hover:scale-105 transition-all duration-300">
      <Sword size={20} class="text-turquoise mb-2" />
      <h3 class="font-bold mb-2 group-hover:text-turquoise transition-colors">Epic Battles</h3>
      <p class="text-sm text-dream-ghost/60">
        Strategic warfare between unique factions and legendary warriors
      </p>
    </div>

    <div class="dream-card group hover:scale-105 transition-all duration-300">
      <Users size={20} class="text-turquoise mb-2" />
      <h3 class="font-bold mb-2 group-hover:text-turquoise transition-colors">Diverse Factions</h3>
      <p class="text-sm text-dream-ghost/60">
        Multiple playable races with unique abilities and histories
      </p>
    </div>
  </div>

  <!-- Quick Actions -->
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div class="dream-card group hover:scale-105 transition-all duration-300">
      <div class="flex flex-col h-full">
        <div class="flex items-center space-x-3 mb-4">
          <Shield class="text-turquoise" size={24} />
          <h3 class="text-lg font-bold group-hover:text-turquoise transition-colors">
            Contribution Guidelines
          </h3>
        </div>
        <p class="text-dream-ghost/60 mb-6 flex-grow">
          Learn how to contribute to this realm and earn rewards for your creative work.
        </p>
        <button class="dream-button w-full mt-auto">View Guidelines</button>
      </div>
    </div>

    <div class="dream-card group hover:scale-105 transition-all duration-300">
      <div class="flex flex-col h-full">
        <div class="flex items-center space-x-3 mb-4">
          <Shield class="text-turquoise" size={24} />
          <h3 class="text-lg font-bold group-hover:text-turquoise transition-colors">
            Active Bounties
          </h3>
        </div>
        <p class="text-dream-ghost/60 mb-6 flex-grow">
          Explore available bounties and start contributing to earn rewards.
        </p>
        <button class="dream-button w-full mt-auto">View Bounties</button>
      </div>
    </div>

    <div class="dream-card group hover:scale-105 transition-all duration-300">
      <div class="flex flex-col h-full">
        <div class="flex items-center space-x-3 mb-4">
          <Users class="text-turquoise" size={24} />
          <h3 class="text-lg font-bold group-hover:text-turquoise transition-colors">
            Community
          </h3>
        </div>
        <p class="text-dream-ghost/60 mb-6 flex-grow">
          Connect with other creators and collaborate on projects.
        </p>
        <button class="dream-button w-full mt-auto">Join Community</button>
      </div>
    </div>
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>