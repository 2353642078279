import { alchemy } from '../config/alchemy-kit';
import { showError } from '../utils/toast';
import type { UserProfile } from '../types/user';

export async function getProfile(): Promise<UserProfile | null> {
  try {
    const address = await alchemy.core.getDefaultProvider().getSigner().getAddress();
    if (!address) return null;

    // For now, return mock profile data
    // In production, this would fetch from your backend
    return {
      id: address,
      user_id: address,
      username: `user_${address.slice(0, 6)}`,
      role: 'weaver',
      avatar_url: `https://api.dicebear.com/7.x/avataaars/svg?seed=${address}`,
      bio: 'A creative contributor on DreamDeck',
      skills: ['Art', 'Design', 'Writing'],
      experience_points: 1000,
      level: 5,
      reputation_score: 80,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };
  } catch (error: any) {
    console.error('Failed to get profile:', error);
    showError(error.message || 'Failed to get profile');
    return null;
  }
}