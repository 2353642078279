<script lang="ts">
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { Loader2, CheckCircle, XCircle, Blocks, Database, Network } from 'lucide-svelte';
  import { alchemy, testConnection, getLatestBlock } from '$lib/config/alchemy';
  import { showError, showSuccess } from '$lib/utils/toast';

  let connectionStatus = 'pending';
  let blockInfo: any = null;
  let networkInfo: any = null;
  let loading = {
    connection: true,
    block: false,
    network: false
  };
  let error = {
    connection: '',
    block: '',
    network: ''
  };

  onMount(async () => {
    try {
      // Test Alchemy connection
      const { success, network, error: connError } = await testConnection();
      
      if (success && network) {
        connectionStatus = 'connected';
        networkInfo = network;
        showSuccess('Successfully connected to Alchemy');
        
        // Get latest block
        await refreshBlockInfo();
      } else {
        throw new Error(connError || 'Connection failed');
      }
    } catch (err: any) {
      connectionStatus = 'error';
      error.connection = err.message;
      showError('Failed to connect to Alchemy');
    } finally {
      loading.connection = false;
    }
  });

  async function refreshBlockInfo() {
    loading.block = true;
    error.block = '';
    try {
      const block = await getLatestBlock();
      blockInfo = {
        number: block.number,
        timestamp: new Date(Number(block.timestamp) * 1000).toLocaleString(),
        hash: block.hash
      };
    } catch (err: any) {
      error.block = err.message;
      showError('Failed to fetch block info');
    } finally {
      loading.block = false;
    }
  }
</script>

<div class="min-h-screen bg-cod-gray p-6">
  <!-- Header -->
  <div class="dream-card mb-6">
    <h1 class="text-3xl font-bold mb-2">Alchemy Connection Test</h1>
    <p class="text-dream-ghost/60">Testing connection to Alchemy on Arbitrum Sepolia</p>
  </div>

  <!-- Status Cards -->
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <!-- Connection Status -->
    <div class="dream-card">
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center space-x-3">
          <Network size={24} class="text-turquoise" />
          <h2 class="text-xl font-bold">Connection</h2>
        </div>
        <div>
          {#if loading.connection}
            <Loader2 size={20} class="animate-spin text-turquoise" />
          {:else if connectionStatus === 'connected'}
            <CheckCircle size={20} class="text-turquoise" />
          {:else}
            <XCircle size={20} class="text-red-500" />
          {/if}
        </div>
      </div>

      {#if error.connection}
        <div class="dream-card bg-red-500/10 text-red-500 text-sm">
          {error.connection}
        </div>
      {:else if connectionStatus === 'connected'}
        <p class="text-dream-ghost/60">Successfully connected to Alchemy</p>
      {/if}
    </div>

    <!-- Network Info -->
    <div class="dream-card">
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center space-x-3">
          <Database size={24} class="text-turquoise" />
          <h2 class="text-xl font-bold">Network</h2>
        </div>
      </div>

      {#if networkInfo}
        <div class="space-y-2 text-sm">
          <div class="flex justify-between">
            <span class="text-dream-ghost/60">Name:</span>
            <span>{networkInfo.name}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-dream-ghost/60">Chain ID:</span>
            <span>{networkInfo.chainId}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-dream-ghost/60">Type:</span>
            <span>Testnet</span>
          </div>
        </div>
      {/if}
    </div>

    <!-- Block Info -->
    <div class="dream-card">
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center space-x-3">
          <Blocks size={24} class="text-turquoise" />
          <h2 class="text-xl font-bold">Latest Block</h2>
        </div>
        <button 
          class="dream-button py-1 px-3"
          on:click={refreshBlockInfo}
          disabled={loading.block}
        >
          {#if loading.block}
            <Loader2 size={16} class="animate-spin" />
          {:else}
            Refresh
          {/if}
        </button>
      </div>

      {#if error.block}
        <div class="dream-card bg-red-500/10 text-red-500 text-sm">
          {error.block}
        </div>
      {:else if blockInfo}
        <div class="space-y-2 text-sm">
          <div class="flex justify-between">
            <span class="text-dream-ghost/60">Number:</span>
            <span>{blockInfo.number}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-dream-ghost/60">Time:</span>
            <span>{blockInfo.timestamp}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-dream-ghost/60">Hash:</span>
            <span class="font-mono truncate ml-4" title={blockInfo.hash}>
              {blockInfo.hash?.slice(0, 10)}...{blockInfo.hash?.slice(-8)}
            </span>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>