import { writable } from 'svelte/store';
import type { Bounty, BountyFilter, BountySubmission } from '../types';
import { showSuccess, showError } from '../utils/toast';

interface BountyState {
  bounties: Bounty[];
  filters: BountyFilter;
  loading: boolean;
  error: string | null;
}

function createBountyStore() {
  const { subscribe, set, update } = writable<BountyState>({
    bounties: [
      {
        id: '1',
        realmId: 'mindborn',
        title: 'Design Yaksha Warrior Concept Art',
        description: 'Create detailed concept art for a Yaksha warrior, incorporating their sun-based powers and metalworking heritage.',
        requirements: [
          'High-resolution character design (front, back, side views)',
          'Color palette matching the Yaksha theme',
          'Detailed weapon and armor designs',
          'Brief description of design choices'
        ],
        rewards: {
          tokens: 500,
          xp: 1000,
          badges: [{
            id: 'art-master',
            name: 'Art Master',
            description: 'Created exceptional character art',
            icon: 'https://api.dicebear.com/7.x/shapes/svg?seed=artmaster',
            rarity: 'rare'
          }]
        },
        deadline: '2024-04-15T00:00:00Z',
        status: 'open',
        difficulty: 'intermediate',
        tags: ['art', 'character-design', 'yaksha'],
        submissions: [],
        createdAt: '2024-03-15T10:00:00Z',
        createdBy: '0x123...',
      },
      {
        id: '2',
        realmId: 'mindborn',
        title: 'Write the Legend of the First Yaksha Smith',
        description: 'Craft an origin story about the first Yaksha metalsmith who discovered how to forge weapons using sun-energy. This tale should explain how the Yakshas became the world\'s first blacksmiths.',
        requirements: [
          'Story length: 2,000-3,000 words',
          'Must incorporate established Yaksha lore and sun-energy mechanics',
          'Include descriptions of the first forging techniques',
          'Explain the cultural impact on Yaksha society',
          'Reference the relationship with the Seven Sages'
        ],
        rewards: {
          tokens: 750,
          xp: 1500,
          badges: [{
            id: 'lore-master',
            name: 'Lore Master',
            description: 'Created foundational lore',
            icon: 'https://api.dicebear.com/7.x/shapes/svg?seed=loremaster',
            rarity: 'epic'
          }]
        },
        deadline: '2024-04-20T00:00:00Z',
        status: 'open',
        difficulty: 'advanced',
        tags: ['story', 'lore', 'yaksha', 'world-building'],
        submissions: [],
        createdAt: '2024-03-16T10:00:00Z',
        createdBy: '0x456...',
        aiAssistant: 'g-673b571f717c8191b04493e2af3632d3-mbs-lore-assistant'
      }
    ],
    filters: {},
    loading: false,
    error: null
  });

  return {
    subscribe,
    
    loadBounties: async (realmId?: string) => {
      update(state => ({ ...state, loading: true, error: null }));
      
      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        update(state => ({
          ...state,
          bounties: realmId 
            ? state.bounties.filter(b => b.realmId === realmId)
            : state.bounties,
          loading: false
        }));
      } catch (error: any) {
        update(state => ({
          ...state,
          error: error.message || 'Failed to load bounties',
          loading: false
        }));
      }
    },

    loadAllBounties: async () => {
      update(state => ({ ...state, loading: true, error: null }));
      
      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        update(state => ({
          ...state,
          loading: false
        }));
      } catch (error: any) {
        update(state => ({
          ...state,
          error: error.message || 'Failed to load bounties',
          loading: false
        }));
      }
    },

    setFilters: (filters: BountyFilter) => {
      update(state => ({ ...state, filters }));
    },

    submitBounty: async (bountyId: string, submission: Omit<BountySubmission, 'id' | 'submittedAt' | 'status'>) => {
      update(state => ({ ...state, loading: true, error: null }));
      
      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        update(state => {
          const bounties = state.bounties.map(bounty => {
            if (bounty.id === bountyId) {
              return {
                ...bounty,
                status: 'in_progress' as const,
                assignedTo: submission.weaverId
              };
            }
            return bounty;
          });
          
          return { ...state, bounties, loading: false };
        });

        showSuccess('Bounty submission successful!');
      } catch (error: any) {
        showError(error.message || 'Failed to submit bounty');
        update(state => ({
          ...state,
          error: error.message || 'Failed to submit bounty',
          loading: false
        }));
      }
    }
  };
}

export const bountyStore = createBountyStore();