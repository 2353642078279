<script lang="ts">
  import { UserPlus, Search, Shield, Star } from 'lucide-svelte';
  import type { Realm } from '../../types';

  export let realm: Realm;

  const members = [
    {
      name: 'Alex Chen',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=alex',
      role: 'Weaver',
      contributions: 24,
      joined: '2 months ago'
    },
    {
      name: 'Sarah Williams',
      avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=sarah',
      role: 'Architect',
      contributions: 156,
      joined: '6 months ago'
    }
  ];

  let searchQuery = '';
</script>

<div class="space-y-6">
  <!-- Header with Search -->
  <div class="dream-card">
    <div class="flex flex-col md:flex-row md:items-center justify-between gap-4">
      <h2 class="text-2xl font-bold">Members</h2>
      <div class="flex items-center space-x-4">
        <div class="relative">
          <input
            type="text"
            bind:value={searchQuery}
            placeholder="Search members..."
            class="dream-input pl-10"
          />
          <Search size={18} class="absolute left-3 top-1/2 -translate-y-1/2 text-dream-ghost/40" />
        </div>
        <button class="dream-button">
          <UserPlus size={20} class="mr-2" />
          Invite Members
        </button>
      </div>
    </div>
  </div>

  <!-- Members List -->
  <div class="space-y-4">
    {#each members as member}
      <div class="dream-card group hover:scale-[1.02] transition-all duration-300">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <img
              src={member.avatar}
              alt={member.name}
              class="w-12 h-12 rounded-lg"
            />
            <div>
              <h3 class="font-bold group-hover:text-turquoise transition-colors">
                {member.name}
              </h3>
              <div class="flex items-center space-x-4 text-sm text-dream-ghost/60">
                <div class="flex items-center">
                  <Shield size={16} class="mr-1" />
                  <span>{member.role}</span>
                </div>
                <div class="flex items-center">
                  <Star size={16} class="mr-1" />
                  <span>{member.contributions} contributions</span>
                </div>
              </div>
            </div>
          </div>
          <span class="text-sm text-dream-ghost/40">{member.joined}</span>
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .dream-input {
    @apply bg-surface border border-turquoise/20 rounded-lg px-4 py-2
           text-white placeholder-white/40
           focus:outline-none focus:ring-2 focus:ring-turquoise/30
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>