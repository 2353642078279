<script lang="ts">
  import { onMount } from 'svelte';
  import { connectWallet, disconnectWallet, isWalletConnected, formatAddress } from '../services/wallet';
  import { authStore } from '../stores/auth';
  
  let connected = false;
  let address = '';
  let error = '';
  let loading = false;

  async function handleConnect() {
    try {
      loading = true;
      error = '';
      const session = await connectWallet();
      if (session) {
        connected = true;
        address = session.address;
      }
    } catch (err: any) {
      console.error('Connection error:', err);
      error = err.message || 'Failed to connect wallet';
    } finally {
      loading = false;
    }
  }

  async function handleDisconnect() {
    try {
      loading = true;
      error = '';
      await disconnectWallet();
      connected = false;
      address = '';
    } catch (err: any) {
      console.error('Disconnection error:', err);
      error = err.message || 'Failed to disconnect wallet';
    } finally {
      loading = false;
    }
  }

  onMount(async () => {
    try {
      const session = $authStore;
      if (session?.address) {
        connected = true;
        address = session.address;
      } else {
        connected = await isWalletConnected();
      }
    } catch (err) {
      console.error('Initialization error:', err);
      error = err.message || 'Failed to initialize wallet connection';
    }
  });
</script>

<div class="wallet-connect" role="region" aria-labelledby="wallet-connect-title">
  <h2 id="wallet-connect-title" class="sr-only">Web3 Wallet Connection</h2>
  
  {#if error}
    <div 
      class="error-message" 
      role="alert" 
      aria-live="assertive"
    >
      {error}
    </div>
  {/if}
  
  {#if !connected}
    <button
      class="connect-button"
      on:click={handleConnect}
      disabled={loading}
      aria-busy={loading}
      aria-label="Connect Web3 Wallet"
    >
      {loading ? 'Connecting...' : 'Connect Wallet'}
    </button>
  {:else}
    <div 
      class="wallet-info" 
      role="status" 
      aria-label="Connected Wallet Details"
    >
      <p 
        class="address" 
        aria-label="Connected Wallet Address"
      >
        Connected: <span aria-hidden="true">{formatAddress(address)}</span>
        <span class="sr-only">{address}</span>
      </p>
      <button
        class="disconnect-button"
        on:click={handleDisconnect}
        disabled={loading}
        aria-busy={loading}
        aria-label="Disconnect Web3 Wallet"
      >
        {loading ? 'Disconnecting...' : 'Disconnect'}
      </button>
    </div>
  {/if}
</div>

<style>
  .wallet-connect {
    @apply flex flex-col gap-4 w-full max-w-md mx-auto p-4;
  }

  .error-message {
    @apply text-red-500 text-sm p-2 rounded bg-red-100/10;
  }

  .connect-button, .disconnect-button {
    @apply px-6 py-3 rounded-lg font-medium transition-all duration-200
           bg-dream-surface border border-dream-border
           hover:border-turquoise hover:shadow-[0_0_20px_rgba(18,235,255,0.3)]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .wallet-info {
    @apply flex flex-col gap-2 p-4 rounded-lg
           bg-dream-surface border border-dream-border;
  }

  .address {
    @apply text-dream-ghost text-sm truncate;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
</style>
