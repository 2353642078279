<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Code, Image, MessageSquare, Target, ArrowUpRight, Users } from 'lucide-svelte';
  import { bountyStore } from '$lib/stores/bounties';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import StoryForge from '$lib/components/CreationHub/StoryForge.svelte';
  import AIPlayground from '$lib/components/CreationHub/AIPlayground.svelte';
  import TrendingProjects from '$lib/components/CreationHub/TrendingProjects.svelte';
  import CreatorStats from '$lib/components/CreationHub/CreatorStats.svelte';

  onMount(() => {
    bountyStore.loadBounties();
  });

  $: bounties = $bountyStore.bounties;
  $: loading = $bountyStore.loading;

  const creativeTools = [
    {
      name: 'Art Generator',
      description: 'Create stunning artwork with AI',
      icon: Image,
      color: 'text-turquoise',
      path: '/art-generator'
    },
    {
      name: 'Code Studio',
      description: 'Generate and optimize code',
      icon: Code,
      color: 'text-turquoise',
      type: 'development'
    },
    {
      name: 'Story Forge',
      description: 'Craft narratives and dialogues',
      icon: MessageSquare,
      color: 'text-turquoise',
      type: 'writing'
    }
  ];

  let activeTab = 'art';
  let selectedBounty = null;
  let selectedTool = null;

  // Filter bounties by category
  $: artBounties = bounties.filter(b => b.tags.some(t => t.includes('art')));
  $: codeBounties = bounties.filter(b => b.tags.some(t => t.includes('dev') || t.includes('code')));
  $: storyBounties = bounties.filter(b => b.tags.some(t => t.includes('story') || t.includes('writing')));

  function handleToolClick(tool) {
    if (tool.path) {
      push(tool.path);
    } else {
      activeTab = tool.type;
    }
  }

  function handleBountySelect(bounty) {
    selectedBounty = bounty;
    selectedTool = creativeTools.find(tool => {
      if (bounty.tags.includes('art')) return tool.name === 'Art Generator';
      if (bounty.tags.includes('code')) return tool.name === 'Code Studio';
      if (bounty.tags.includes('story')) return tool.name === 'Story Forge';
      return false;
    });
  }
</script>

<div class="min-h-screen bg-cod-gray">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <!-- Header -->
    <div class="card mb-8">
      <h1 class="text-4xl font-bold mb-2">Creation Hub</h1>
      <p class="text-white/60">Unleash your creativity with AI-powered tools</p>
    </div>

    <!-- Creative Tools -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      {#each creativeTools as tool}
        <button
          class="card card-hover text-left {activeTab === tool.type ? 'border-2 border-turquoise' : ''}"
          on:click={() => handleToolClick(tool)}
        >
          <div class="flex items-start space-x-4">
            <div class="p-3 rounded-lg bg-surface-light {tool.color}">
              <svelte:component this={tool.icon} size={24} />
            </div>
            <div>
              <h3 class="font-bold group-hover:text-turquoise transition-colors mb-2">
                {tool.name}
              </h3>
              <p class="text-sm text-white/60">{tool.description}</p>
            </div>
          </div>
        </button>
      {/each}
    </div>

    <!-- Available Bounties -->
    <div class="card mb-8">
      <div class="flex items-center space-x-2 mb-6">
        <Target class="text-turquoise" size={24} />
        <h2 class="text-xl font-bold">Available Bounties</h2>
      </div>

      <!-- Category Tabs -->
      <div class="flex space-x-4 mb-6 border-b border-turquoise/10">
        <button
          class="px-4 py-2 {activeTab === 'art' ? 'text-turquoise border-b-2 border-turquoise' : 'text-white/60'}"
          on:click={() => activeTab = 'art'}
        >
          Art ({artBounties.length})
        </button>
        <button
          class="px-4 py-2 {activeTab === 'development' ? 'text-turquoise border-b-2 border-turquoise' : 'text-white/60'}"
          on:click={() => activeTab = 'development'}
        >
          Code ({codeBounties.length})
        </button>
        <button
          class="px-4 py-2 {activeTab === 'writing' ? 'text-turquoise border-b-2 border-turquoise' : 'text-white/60'}"
          on:click={() => activeTab = 'writing'}
        >
          Story ({storyBounties.length})
        </button>
      </div>

      <!-- Content Area -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        {#if loading}
          {#each Array(4) as _}
            <div class="card animate-pulse">
              <div class="h-6 bg-surface-light rounded w-1/3 mb-4"></div>
              <div class="h-4 bg-surface-light rounded w-2/3"></div>
            </div>
          {/each}
        {:else}
          {#if activeTab === 'art'}
            {#each artBounties as bounty}
              <button
                class="card bg-surface-light text-left hover:scale-[1.02] transition-all {selectedBounty?.id === bounty.id ? 'border-2 border-turquoise' : ''}"
                on:click={() => handleBountySelect(bounty)}
              >
                <h4 class="font-bold mb-2">{bounty.title}</h4>
                <p class="text-sm text-white/60 mb-4">{bounty.description}</p>
                <div class="flex items-center justify-between">
                  <div class="flex space-x-2">
                    {#each bounty.tags as tag}
                      <span class="px-2 py-1 text-xs rounded-full bg-surface text-white/60">
                        {tag}
                      </span>
                    {/each}
                  </div>
                  <span class="text-turquoise">{bounty.rewards.tokens} DREAMS</span>
                </div>
              </button>
            {/each}
          {:else if activeTab === 'development'}
            {#each codeBounties as bounty}
              <button
                class="card bg-surface-light text-left hover:scale-[1.02] transition-all {selectedBounty?.id === bounty.id ? 'border-2 border-turquoise' : ''}"
                on:click={() => handleBountySelect(bounty)}
              >
                <h4 class="font-bold mb-2">{bounty.title}</h4>
                <p class="text-sm text-white/60 mb-4">{bounty.description}</p>
                <div class="flex items-center justify-between">
                  <div class="flex space-x-2">
                    {#each bounty.tags as tag}
                      <span class="px-2 py-1 text-xs rounded-full bg-surface text-white/60">
                        {tag}
                      </span>
                    {/each}
                  </div>
                  <span class="text-turquoise">{bounty.rewards.tokens} DREAMS</span>
                </div>
              </button>
            {/each}
          {:else if activeTab === 'writing'}
            <div class="col-span-2">
              <StoryForge bounty={selectedBounty} />
            </div>
            {#each storyBounties as bounty}
              <button
                class="card bg-surface-light text-left hover:scale-[1.02] transition-all {selectedBounty?.id === bounty.id ? 'border-2 border-turquoise' : ''}"
                on:click={() => handleBountySelect(bounty)}
              >
                <h4 class="font-bold mb-2">{bounty.title}</h4>
                <p class="text-sm text-white/60 mb-4">{bounty.description}</p>
                <div class="flex items-center justify-between">
                  <div class="flex space-x-2">
                    {#each bounty.tags as tag}
                      <span class="px-2 py-1 text-xs rounded-full bg-surface text-white/60">
                        {tag}
                      </span>
                    {/each}
                  </div>
                  <span class="text-turquoise">{bounty.rewards.tokens} DREAMS</span>
                </div>
              </button>
            {/each}
          {/if}
        {/if}
      </div>
    </div>

    <!-- Stats Grid -->
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <!-- Trending Projects -->
      <div class="lg:col-span-2">
        <TrendingProjects />
      </div>

      <!-- Creator Stats -->
      <div>
        <CreatorStats />
      </div>
    </div>
  </div>
</div>