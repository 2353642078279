<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Book, Search, Compass, Users, Sparkles, HelpCircle, Calendar, Bell, MessageSquare, Filter } from 'lucide-svelte';
  import { push } from 'svelte-spa-router';

  let searchQuery = '';
  let showFilters = false;

  const categories = [
    {
      id: 'getting-started',
      title: 'Getting Started',
      icon: Compass,
      color: 'text-turquoise',
      articles: [
        { title: 'What is Dreamdeck?', slug: 'what-is-dreamdeck' },
        { title: 'Signing up for an account', slug: 'signup' },
        { title: 'Navigating the interface', slug: 'navigation' },
        { title: 'Setting up your profile', slug: 'profile-setup' },
        { title: 'Joining communities', slug: 'joining-communities' }
      ]
    },
    {
      id: 'collaboration',
      title: 'Collaboration Basics',
      icon: Users,
      color: 'text-turquoise',
      articles: [
        { title: 'Finding projects', slug: 'find-projects' },
        { title: 'Starting your own project', slug: 'start-project' },
        { title: 'Inviting collaborators', slug: 'invite-collaborators' },
        { title: 'Communication tools', slug: 'communication' },
        { title: 'File sharing & version control', slug: 'file-sharing' }
      ]
    },
    {
      id: 'resources',
      title: 'Creative Resources',
      icon: Sparkles,
      color: 'text-turquoise',
      articles: [
        { title: 'Brainstorming techniques', slug: 'brainstorming' },
        { title: 'Storytelling guides', slug: 'storytelling' },
        { title: 'Character development', slug: 'character-design' },
        { title: 'Game design basics', slug: 'game-design' },
        { title: 'Art resources', slug: 'art-resources' }
      ]
    },
    {
      id: 'guidelines',
      title: 'Community Guidelines',
      icon: Book,
      color: 'text-turquoise',
      articles: [
        { title: 'Code of conduct', slug: 'code-of-conduct' },
        { title: 'Respect & inclusivity', slug: 'inclusivity' },
        { title: 'Constructive feedback', slug: 'feedback' },
        { title: 'Conflict resolution', slug: 'conflicts' },
        { title: 'Reporting issues', slug: 'reporting' }
      ]
    },
    {
      id: 'events',
      title: 'Events & Challenges',
      icon: Calendar,
      color: 'text-turquoise',
      articles: [
        { title: 'Upcoming events', slug: 'events' },
        { title: 'Participation guides', slug: 'participation' },
        { title: 'Past highlights', slug: 'highlights' },
        { title: 'Community initiatives', slug: 'initiatives' },
        { title: 'Skill workshops', slug: 'workshops' }
      ]
    },
    {
      id: 'support',
      title: 'Contact & Support',
      icon: MessageSquare,
      color: 'text-turquoise',
      articles: [
        { title: 'Support channels', slug: 'support' },
        { title: 'Bug reporting', slug: 'bugs' },
        { title: 'Feature requests', slug: 'features' },
        { title: 'Partnership inquiries', slug: 'partnerships' },
        { title: 'Press relations', slug: 'press' }
      ]
    }
  ];

  $: filteredCategories = searchQuery
    ? categories.map(category => ({
        ...category,
        articles: category.articles.filter(article =>
          article.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
      })).filter(category => category.articles.length > 0)
    : categories;

  function handleArticleClick(slug: string) {
    push(`/wiki/${slug}`);
  }
</script>

<div class="min-h-screen bg-cod-gray p-6">
  <!-- Header -->
  <div class="dream-card mb-6" in:fade>
    <h1 class="text-3xl font-bold mb-2">Dreamdeck Wiki</h1>
    <p class="text-dream-ghost/60">
      Your comprehensive guide to understanding and making the most of the Dreamdeck platform
    </p>
  </div>

  <!-- Search and Filters -->
  <div class="dream-card mb-6" in:fade={{ delay: 200 }}>
    <div class="flex justify-between items-center">
      <div class="relative flex-1 max-w-xl">
        <input
          type="text"
          bind:value={searchQuery}
          placeholder="Search articles..."
          class="dream-input w-full pl-12"
        />
        <Search size={20} class="absolute left-4 top-1/2 -translate-y-1/2 text-white/40" />
      </div>
      <button 
        class="btn btn-secondary ml-4"
        on:click={() => showFilters = !showFilters}
      >
        <Filter size={20} class="mr-2" />
        Filters
      </button>
    </div>
  </div>

  <!-- Categories Grid -->
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {#each filteredCategories as category}
      <div 
        class="dream-card group hover:scale-[1.02] transition-all duration-300"
        in:fade={{ delay: 300 }}
      >
        <div class="flex items-center space-x-3 mb-6">
          <div class="p-3 rounded-lg bg-surface/50 {category.color}">
            <svelte:component this={category.icon} size={24} />
          </div>
          <h2 class="text-xl font-bold group-hover:text-turquoise transition-colors">
            {category.title}
          </h2>
        </div>

        <ul class="space-y-3">
          {#each category.articles as article}
            <li>
              <button 
                class="flex items-center w-full text-left text-dream-ghost/60 hover:text-turquoise transition-colors"
                on:click={() => handleArticleClick(article.slug)}
              >
                <span class="mr-2">→</span>
                {article.title}
              </button>
            </li>
          {/each}
        </ul>
      </div>
    {/each}
  </div>

  <!-- Help Card -->
  <div class="mt-6 dream-card bg-surface/50" in:fade={{ delay: 400 }}>
    <div class="flex items-center justify-between">
      <div class="flex items-center space-x-4">
        <div class="p-3 rounded-lg bg-surface/30 text-turquoise">
          <HelpCircle size={24} />
        </div>
        <div>
          <h3 class="text-lg font-bold mb-1">Need more help?</h3>
          <p class="text-dream-ghost/60">Can't find what you're looking for? Reach out to our support team.</p>
        </div>
      </div>
      <button class="dream-button">
        Contact Support
      </button>
    </div>
  </div>
</div>

<style>
  .dream-input {
    @apply bg-surface border border-turquoise/20 rounded-lg px-4 py-3
           text-white placeholder-white/40
           focus:outline-none focus:ring-2 focus:ring-turquoise/30
           disabled:opacity-50 disabled:cursor-not-allowed
           w-full transition-all duration-300;
  }

  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-secondary {
    @apply bg-surface text-white border border-turquoise/20
           hover:border-turquoise/40 hover:bg-surface-light;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>