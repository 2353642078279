import { writable } from 'svelte/store';
import type { Mentor, MentorshipSession } from '../types/mentorship';

interface MentorshipState {
  mentors: Mentor[];
  sessions: MentorshipSession[];
  loading: boolean;
  error: string | null;
}

function createMentorshipStore() {
  const { subscribe, set, update } = writable<MentorshipState>({
    mentors: [],
    sessions: [],
    loading: false,
    error: null
  });

  return {
    subscribe,
    
    loadMentors: async () => {
      update(state => ({ ...state, loading: true, error: null }));
      
      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const mockMentors: Mentor[] = [
          {
            id: '1',
            name: 'Master Chen',
            avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=chen',
            bio: 'Expert in combat systems and character progression',
            skills: ['Combat Design', 'Balance', 'Systems'],
            rating: 4.9,
            availability: '20hrs/week',
            apprenticeCount: 3,
            successfulMentorships: 12,
            specialties: ['Combat', 'Systems'],
            yearsOfExperience: 5,
            timeZone: 'UTC+8',
            languages: ['English', 'Chinese']
          }
        ];

        update(state => ({
          ...state,
          mentors: mockMentors,
          loading: false
        }));
      } catch (error: any) {
        update(state => ({
          ...state,
          error: error.message || 'Failed to load mentors',
          loading: false
        }));
      }
    },

    requestMentorship: async (mentorId: string, skillFocus: string[]) => {
      update(state => ({ ...state, loading: true, error: null }));
      
      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const newSession: MentorshipSession = {
          id: Date.now().toString(),
          mentorId,
          apprenticeId: 'current-user',
          skillFocus,
          startDate: new Date().toISOString(),
          status: 'active',
          milestones: []
        };

        update(state => ({
          ...state,
          sessions: [...state.sessions, newSession],
          loading: false
        }));

        return newSession;
      } catch (error: any) {
        update(state => ({
          ...state,
          error: error.message || 'Failed to request mentorship',
          loading: false
        }));
        throw error;
      }
    }
  };
}

export const mentorshipStore = createMentorshipStore();