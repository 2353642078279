<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { Timer } from 'lucide-svelte';
  import { push } from 'svelte-spa-router';
  import { authStore } from '$lib/stores/auth';
  import TextGenerateEffect from '$lib/components/ui/TextGenerateEffect.svelte';
  import Logo from '$lib/components/ui/Logo.svelte';
  import WalletConnect from '$lib/components/WalletConnect.svelte';

  const stats = [
    { value: '10K+', label: 'Active Creators' },
    { value: '50+', label: 'Active Realms' },
    { value: '100K', label: 'DREAMS Earned' },
    { value: '1K+', label: 'Completed Bounties' }
  ];

  function handleWalletConnect(event: CustomEvent) {
    const { provider, address } = event.detail;
    authStore.login(provider, address);
  }
</script>

<div class="min-h-screen bg-cod-gray text-white flex flex-col items-center justify-center p-4">
  <div class="max-w-md w-full space-y-8">
    <div class="text-center">
      <h1 class="text-4xl font-bold mb-2">Welcome to Dreamdeck</h1>
      <p class="text-dream-ghost mb-8">Connect your wallet to continue</p>
    </div>
    
    <WalletConnect on:success={handleWalletConnect} />
  </div>
</div>

<style>
  .card {
    @apply bg-dream-surface/80 backdrop-blur border border-dream-border rounded-lg p-4 flex flex-col items-center justify-center transition-all duration-300;
  }

  .card-hover:hover {
    @apply border-turquoise/20 translate-y-[-2px] shadow-glass-hover;
  }
</style>