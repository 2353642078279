<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { Wand2, Image, Search, Loader2, ArrowLeft } from 'lucide-svelte';
  import { getPromptSuggestions, generateArt } from '$lib/services/art';
  import { artStore } from '$lib/stores/art';
  import { push } from 'svelte-spa-router';

  let selectedRealm = 'mindborn';
  let concept = '';
  let loading = false;
  let generating = false;
  let suggestions: any[] = [];
  let selectedPrompt: any = null;
  let finalPrompt = '';
  let generatedArt: any = null;

  const artStyles = [
    { id: 'digital', label: 'Digital Art' },
    { id: '3d', label: '3D Render' },
    { id: 'oil', label: 'Oil Painting' },
    { id: 'watercolor', label: 'Watercolor' },
    { id: 'pixel', label: 'Pixel Art' },
    { id: 'comic', label: 'Comic Book' },
    { id: 'fantasy', label: 'Fantasy' },
    { id: 'cyberpunk', label: 'Cyberpunk' },
    { id: 'minimalist', label: 'Minimalist' }
  ];

  let selectedStyle = 'fantasy';

  async function handleGetSuggestions() {
    if (!concept) return;
    
    loading = true;
    suggestions = await getPromptSuggestions(selectedRealm, concept);
    loading = false;
  }

  function selectPrompt(suggestion: any) {
    selectedPrompt = suggestion;
    finalPrompt = `${suggestion.title}:\n${suggestion.fullPrompt}`;
  }

  async function handleGenerateArt() {
    if (!finalPrompt) return;
    
    generating = true;
    const result = await generateArt(finalPrompt, selectedStyle);
    
    if (result) {
      generatedArt = result;
      await artStore.shareToRealm(result.url, finalPrompt, selectedRealm);
    }
    
    generating = false;
  }

  function handleBack() {
    push(`/realms/${selectedRealm}`);
  }

  function clearGeneration() {
    generatedArt = null;
    selectedPrompt = null;
    finalPrompt = '';
  }
</script>

<div class="min-h-screen bg-cod-gray">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <!-- Header -->
    <div class="card mb-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <button 
            class="p-2 rounded-lg hover:bg-surface-light transition-colors text-white/60 hover:text-white"
            on:click={handleBack}
          >
            <ArrowLeft size={24} />
          </button>
          <div>
            <h1 class="text-3xl font-bold mb-1">AI Art Generator</h1>
            <p class="text-white/60">Create unique artwork for Mindborn Sons</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Content -->
    {#if !generatedArt}
      <div class="card mb-8">
        <h2 class="text-xl font-bold mb-4">What would you like to create?</h2>
        <div class="space-y-6">
          <!-- Initial Concept Input -->
          <div>
            <label class="block text-sm text-white/60 mb-2">Initial Concept</label>
            <div class="flex space-x-4">
              <input
                type="text"
                bind:value={concept}
                class="input flex-1"
                placeholder="Enter your concept (e.g., 'Naga warrior in battle')"
              />
              <button
                class="btn"
                on:click={handleGetSuggestions}
                disabled={!concept || loading}
              >
                {#if loading}
                  <Loader2 size={20} class="mr-2 animate-spin" />
                  Getting Ideas...
                {:else}
                  <Wand2 size={20} class="mr-2" />
                  Get Ideas
                {/if}
              </button>
            </div>
          </div>

          <!-- Art Style Selection -->
          <div>
            <label class="block text-sm text-white/60 mb-2">Art Style</label>
            <div class="flex flex-wrap gap-2">
              {#each artStyles as style}
                <button
                  class="px-3 py-1 rounded-full transition-colors
                    {selectedStyle === style.id ? 
                      'bg-turquoise text-cod-gray' : 
                      'bg-surface-light text-white/60 hover:text-white'}"
                  on:click={() => selectedStyle = style.id}
                >
                  {style.label}
                </button>
              {/each}
            </div>
          </div>
        </div>
      </div>

      <!-- Prompt Suggestions -->
      {#if suggestions.length > 0}
        <div class="card mb-8">
          <h2 class="text-xl font-bold mb-4">Select a Prompt</h2>
          <div class="space-y-4">
            {#each suggestions as suggestion}
              <button
                class="card bg-surface-light text-left w-full hover:scale-[1.02] transition-all
                  {selectedPrompt?.title === suggestion.title ? 'border-2 border-turquoise' : ''}"
                on:click={() => selectPrompt(suggestion)}
              >
                <div class="space-y-2">
                  <h3 class="font-bold text-turquoise">{suggestion.title}</h3>
                  <p class="text-white/80 text-sm whitespace-pre-line">{suggestion.fullPrompt}</p>
                </div>
              </button>
            {/each}
          </div>
        </div>
      {/if}

      <!-- Final Prompt -->
      {#if finalPrompt}
        <div class="card">
          <h2 class="text-xl font-bold mb-4">Generate Art</h2>
          <div class="space-y-4">
            <div>
              <label class="block text-sm text-white/60 mb-2">Final Prompt</label>
              <textarea
                bind:value={finalPrompt}
                class="input w-full h-32 font-mono text-sm"
                placeholder="Your complete art prompt will appear here..."
              ></textarea>
              <p class="text-sm text-white/40 mt-2">
                Feel free to modify the prompt before generating
              </p>
            </div>

            <div class="flex justify-end">
              <button
                class="btn"
                on:click={handleGenerateArt}
                disabled={generating}
              >
                {#if generating}
                  <Loader2 size={20} class="mr-2 animate-spin" />
                  Generating...
                {:else}
                  <Image size={20} class="mr-2" />
                  Generate Art
                {/if}
              </button>
            </div>
          </div>
        </div>
      {/if}
    {:else}
      <!-- Generated Art Display -->
      <div class="card">
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-bold">Generated Artwork</h2>
          <div class="flex space-x-4">
            <button 
              class="btn btn-secondary"
              on:click={clearGeneration}
            >
              Create New
            </button>
            <button 
              class="btn"
              on:click={handleBack}
            >
              Return to Realm
            </button>
          </div>
        </div>

        <img
          src={generatedArt.url}
          alt="Generated artwork"
          class="w-full rounded-lg mb-6"
        />

        <div class="space-y-4">
          <div>
            <h3 class="font-bold mb-2">Prompt Used</h3>
            <p class="text-white/80 text-sm">{finalPrompt}</p>
          </div>

          <div class="flex items-center space-x-4">
            <span class="text-white/60">Style: {selectedStyle}</span>
            <span class="text-white/60">•</span>
            <span class="text-white/60">Shared to Mindborn Sons</span>
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  textarea {
    font-family: 'Roboto Mono', monospace;
    line-height: 1.6;
    resize: vertical;
  }
</style>