import openai from './openai';
import { showError } from '../utils/toast';

export interface PromptSuggestion {
  title: string;
  fullPrompt: string;
  style?: string;
}

export interface ArtGenerationResult {
  url: string;
  prompt: string;
}

export async function getPromptSuggestions(realm: string, concept: string): Promise<PromptSuggestion[]> {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: realm === 'mindborn' 
            ? `You are the Mindborn Sons Creative Director GPT. Generate complete, detailed art prompts that match the Mindborn Sons aesthetic: a world of ancient powers, metal warriors, and mythical beings.

               For each prompt:
               1. Start with a concise title
               2. Follow with a SINGLE, comprehensive prompt that includes:
                  - Subject and composition
                  - Lighting and atmosphere
                  - Color palette
                  - Key visual elements
                  - Textures and materials
                  - Mood and emotional impact
                  
               Format each prompt as:
               Title: [Concise Title]
               [Detailed prompt incorporating all elements above in a cohesive description]
               
               IMPORTANT: Each prompt should be complete and self-contained, ready to use for image generation.`
            : "You are a creative art director. Generate complete, detailed art prompts."
        },
        {
          role: "user",
          content: `Generate 3 complete art prompts for: ${concept}`
        }
      ],
      temperature: 0.7,
      max_tokens: 1000
    });

    const content = response.choices[0].message?.content;
    if (!content) return [];

    // Split into individual prompts and parse
    const promptSections = content.split('\n\n').filter(Boolean);
    
    return promptSections.map(section => {
      const [titleLine, ...promptLines] = section.split('\n');
      const title = titleLine.replace(/^Title:\s*/, '').trim();
      const fullPrompt = promptLines.join('\n').trim();
      
      return {
        title,
        fullPrompt,
        style: realm === 'mindborn' ? 'fantasy' : 'digital'
      };
    });
  } catch (error: any) {
    console.error('Failed to get prompt suggestions:', error);
    showError(error?.message || 'Failed to get prompt suggestions');
    return [];
  }
}

export async function generateArt(prompt: string, style: string): Promise<ArtGenerationResult | null> {
  try {
    // Generate image using DALL-E
    const response = await openai.images.generate({
      model: "dall-e-3",
      prompt: `${prompt}\n\nStyle: ${style}, highly detailed, professional quality, dramatic lighting`,
      n: 1,
      size: "1024x1024",
      quality: "hd",
      style: "vivid"
    });

    if (!response.data?.[0]?.url) {
      throw new Error('No image generated');
    }

    return {
      url: response.data[0].url,
      prompt
    };
  } catch (error: any) {
    showError(error?.message || 'Failed to generate art');
    return null;
  }
}