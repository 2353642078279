<script lang="ts">
  import { Flame, Users, ArrowUpRight } from 'lucide-svelte';

  const trendingProjects = [
    {
      name: 'Cyber Knights',
      description: 'AI-generated cyberpunk character collection',
      contributors: 24,
      growth: '+45%'
    },
    {
      name: 'Dream Worlds',
      description: 'Procedural fantasy environment generator',
      contributors: 18,
      growth: '+32%'
    },
    {
      name: 'Neural Beats',
      description: 'AI-composed game soundtracks',
      contributors: 12,
      growth: '+28%'
    }
  ];
</script>

<div class="dream-card">
  <div class="flex items-center space-x-2 mb-6">
    <Flame class="text-dream-pink" size={24} />
    <h2 class="text-xl font-bold">Trending Projects</h2>
  </div>

  <div class="space-y-4">
    {#each trendingProjects as project}
      <div class="dream-card bg-dream-surface/50 hover:scale-[1.02] transition-all group">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="font-bold group-hover:text-dream-pink transition-colors mb-1">
              {project.name}
            </h3>
            <p class="text-sm text-dream-ghost/60">{project.description}</p>
          </div>
          <div class="flex items-center space-x-4 text-sm">
            <div class="flex items-center text-dream-ghost/40">
              <Users size={16} class="mr-1" />
              <span>{project.contributors}</span>
            </div>
            <div class="text-dream-teal flex items-center">
              <ArrowUpRight size={16} class="mr-1" />
              <span>{project.growth}</span>
            </div>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>