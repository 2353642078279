<script lang="ts">
  import { fade } from 'svelte/transition';
  import { ArrowUpRight } from 'lucide-svelte';

  export let board: {
    title: string;
    icon: any;
    color: string;
    metric: string;
    leaders: Array<{
      name: string;
      value: string;
      change: string;
    }>;
  };
</script>

<div class="card">
  <div class="flex items-center justify-between mb-6">
    <div class="flex items-center space-x-3">
      <div class="p-2 rounded-lg bg-surface-light {board.color}">
        <svelte:component this={board.icon} size={24} />
      </div>
      <h2 class="text-xl font-bold">{board.title}</h2>
    </div>
    <button class="text-sm text-turquoise hover:text-turquoise/80 transition-colors">
      View All
    </button>
  </div>

  <div class="space-y-4">
    {#each board.leaders as leader, i}
      <div class="card bg-surface-light hover:scale-[1.02] transition-all" in:fade>
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div class="w-8 h-8 rounded-full bg-surface flex items-center justify-center text-white/40">
              #{i + 1}
            </div>
            <div>
              <h3 class="font-bold hover:text-turquoise transition-colors">
                {leader.name}
              </h3>
              <p class="text-sm text-white/40">{leader.value} {board.metric}</p>
            </div>
          </div>
          <div class="flex items-center text-turquoise text-sm">
            <ArrowUpRight size={16} class="mr-1" />
            {leader.change}
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>