<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Search, Filter, ArrowUpRight, Clock, Shield } from 'lucide-svelte';
  import { bountyStore } from '$lib/stores/bounties';
  import BountyCard from '$lib/components/Bounty/BountyCard.svelte';
  import BountyFilters from '$lib/components/Bounty/BountyFilters.svelte';
  import { formatTimeLeft } from '$lib/utils/date';
  import { onMount } from 'svelte';

  let searchQuery = '';
  let showFilters = false;

  // Stats
  const marketStats = {
    totalBounties: 0,
    totalValue: 0,
    activeRealms: 0,
    avgCompletion: '3.5 days'
  };

  onMount(() => {
    bountyStore.loadAllBounties();
  });

  $: bounties = $bountyStore.bounties;
  $: loading = $bountyStore.loading;
  $: filteredBounties = bounties.filter(bounty => 
    bounty.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    bounty.description.toLowerCase().includes(searchQuery.toLowerCase())
  );
</script>

<div class="min-h-screen bg-cod-gray">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <!-- Header -->
    <div class="card mb-8">
      <h1 class="text-4xl font-bold mb-2">Bounty Marketplace</h1>
      <p class="text-white/60">Discover opportunities across all realms</p>
    </div>

    <!-- Stats Grid -->
    <div class="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8" in:fade>
      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <Shield size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Total Bounties</span>
        </div>
        <div class="text-2xl font-bold">{marketStats.totalBounties}</div>
      </div>

      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <ArrowUpRight size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Total Value</span>
        </div>
        <div class="text-2xl font-bold">{marketStats.totalValue} DREAMS</div>
      </div>

      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <Shield size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Active Realms</span>
        </div>
        <div class="text-2xl font-bold">{marketStats.activeRealms}</div>
      </div>

      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <Clock size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Avg. Completion</span>
        </div>
        <div class="text-2xl font-bold">{marketStats.avgCompletion}</div>
      </div>
    </div>

    <!-- Search and Filters -->
    <div class="card mb-8">
      <div class="flex justify-between items-center">
        <div class="relative flex-1 max-w-xl">
          <input
            type="text"
            bind:value={searchQuery}
            placeholder="Search bounties..."
            class="input w-full pl-12"
          />
          <Search size={20} class="absolute left-4 top-1/2 -translate-y-1/2 text-white/40" />
        </div>
        <button 
          class="btn btn-secondary ml-4"
          on:click={() => showFilters = !showFilters}
        >
          <Filter size={20} class="mr-2" />
          Filters
        </button>
      </div>
    </div>

    <!-- Content Grid -->
    <div class="grid grid-cols-1 lg:grid-cols-4 gap-8">
      <!-- Filters Sidebar -->
      {#if showFilters}
        <div class="lg:col-span-1" in:fade>
          <BountyFilters />
        </div>
      {/if}

      <!-- Bounties List -->
      <div class={showFilters ? 'lg:col-span-3' : 'lg:col-span-4'}>
        {#if loading}
          <div class="space-y-4">
            {#each Array(3) as _}
              <div class="card animate-pulse">
                <div class="h-6 bg-surface-light rounded w-1/3 mb-4"></div>
                <div class="h-4 bg-surface-light rounded w-2/3 mb-2"></div>
                <div class="h-4 bg-surface-light rounded w-1/2"></div>
              </div>
            {/each}
          </div>
        {:else if filteredBounties.length === 0}
          <div class="card text-center py-12">
            <p class="text-white/60 mb-4">No bounties found</p>
            <p class="text-sm text-white/40">Try adjusting your search or filters</p>
          </div>
        {:else}
          <div class="space-y-4">
            {#each filteredBounties as bounty (bounty.id)}
              <BountyCard {bounty} />
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>