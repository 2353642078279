<script lang="ts">
  import { Shield, Palette, Coins, Bell } from 'lucide-svelte';
  import type { Realm } from '../../types';

  export let realm: Realm;

  const settings = [
    {
      title: 'General Settings',
      description: 'Configure basic realm settings and permissions.',
      icon: Shield,
      color: 'text-turquoise',
      action: 'Manage'
    },
    {
      title: 'Appearance',
      description: 'Customize realm theme and visual elements.',
      icon: Palette,
      color: 'text-turquoise',
      action: 'Customize'
    },
    {
      title: 'Rewards',
      description: 'Manage realm tokens and reward distribution.',
      icon: Coins,
      color: 'text-turquoise',
      action: 'Configure'
    },
    {
      title: 'Notifications',
      description: 'Set up realm notifications and alerts.',
      icon: Bell,
      color: 'text-turquoise',
      action: 'Configure'
    }
  ];
</script>

<div class="space-y-6">
  <!-- Header -->
  <div class="dream-card">
    <h2 class="text-2xl font-bold">Realm Settings</h2>
  </div>

  <!-- Settings Grid -->
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
    {#each settings as setting}
      <div class="dream-card group hover:scale-105 transition-all duration-300">
        <div class="flex flex-col h-full">
          <div class="flex items-center space-x-3 mb-4">
            <div class="p-3 rounded-lg bg-surface/50 {setting.color}">
              <svelte:component this={setting.icon} size={24} />
            </div>
            <h3 class="text-lg font-bold group-hover:text-turquoise transition-colors">
              {setting.title}
            </h3>
          </div>

          <p class="text-dream-ghost/60 mb-6 flex-grow">
            {setting.description}
          </p>

          <button class="dream-button w-full mt-auto">
            {setting.action}
          </button>
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>