<script lang="ts">
  import { FileText, Image, Link } from 'lucide-svelte';
  import type { Realm } from '../../types';

  export let realm: Realm;

  const resources = [
    {
      title: 'Documentation',
      description: 'Access realm documentation, guidelines, and contribution rules.',
      icon: FileText,
      color: 'text-turquoise',
      action: 'View Docs'
    },
    {
      title: 'Asset Library',
      description: 'Download official realm assets, logos, and templates.',
      icon: Image,
      color: 'text-turquoise',
      action: 'Browse Assets'
    },
    {
      title: 'External Links',
      description: 'Important links and resources for realm contributors.',
      icon: Link,
      color: 'text-turquoise',
      action: 'View Links'
    }
  ];
</script>

<div class="space-y-6">
  <!-- Header -->
  <div class="dream-card">
    <h2 class="text-2xl font-bold">Resources</h2>
  </div>

  <!-- Resource Grid -->
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    {#each resources as resource}
      <div class="dream-card group hover:scale-105 transition-all duration-300">
        <div class="flex flex-col h-full">
          <!-- Header -->
          <div class="flex items-center space-x-3 mb-4">
            <div class="p-3 rounded-lg bg-surface/50 {resource.color}">
              <svelte:component this={resource.icon} size={24} />
            </div>
            <h3 class="text-lg font-bold group-hover:text-turquoise transition-colors">
              {resource.title}
            </h3>
          </div>

          <!-- Description -->
          <p class="text-dream-ghost/60 mb-6 flex-grow">
            {resource.description}
          </p>

          <!-- Action Button -->
          <button class="dream-button w-full mt-auto">
            {resource.action}
          </button>
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>