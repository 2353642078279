import { Network, Alchemy } from 'alchemy-sdk';

// Alchemy SDK settings
const settings = {
  apiKey: import.meta.env.VITE_ALCHEMY_API_KEY || '',
  network: Network.ARB_SEPOLIA,
  maxRetries: 3,
  batchRequests: true
};

// Create Alchemy instance
export const alchemy = new Alchemy(settings);

// Test connection function with better error handling
export async function testConnection() {
  try {
    if (!settings.apiKey) {
      throw new Error('Alchemy API key not found. Please check your environment variables.');
    }
    const network = await alchemy.core.getNetwork();
    console.log('Connected to network:', network);
    return {
      success: true,
      network,
      error: null
    };
  } catch (error: any) {
    console.error('Alchemy connection error:', error);
    return {
      success: false,
      network: null,
      error: error?.message || 'Failed to connect to Alchemy'
    };
  }
}

// Get latest block with error handling
export async function getLatestBlock() {
  try {
    const block = await alchemy.core.getBlockNumber();
    return {
      success: true,
      block,
      error: null
    };
  } catch (error: any) {
    console.error('Failed to get latest block:', error);
    return {
      success: false,
      block: null,
      error: error?.message || 'Failed to get latest block'
    };
  }
}