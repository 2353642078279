import toast from 'svelte-french-toast';

export const showSuccess = (message: string) => {
  toast.success(message, {
    style: 'background: #222224; color: #FFFFFF; border: 1px solid rgba(255, 51, 102, 0.2);',
    icon: '🎉'
  });
};

export const showError = (message: string) => {
  toast.error(message, {
    style: 'background: #222224; color: #FFFFFF; border: 1px solid rgba(255, 51, 102, 0.2);',
    icon: '❌'
  });
};

export const showInfo = (message: string) => {
  toast(message, {
    style: 'background: #222224; color: #FFFFFF; border: 1px solid rgba(255, 51, 102, 0.2);',
    icon: 'ℹ️'
  });
};