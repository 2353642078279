import { showError, showSuccess } from '../utils/toast';
import { realmStore } from '../stores/realms';
import { push } from 'svelte-spa-router';

export async function createRealm(data: any) {
  try {
    const realm = await realmStore.createRealm(data);
    if (realm) {
      showSuccess('Realm created successfully!');
      push(`/realms/${realm.id}`); // Navigate to the new realm's page
      return true;
    }
    return false;
  } catch (error: any) {
    showError(error?.message || 'Failed to create realm');
    return false;
  }
}