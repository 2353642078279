<script lang="ts">
  import { Clock, Coins } from 'lucide-svelte';

  const history = [
    {
      title: 'Bounty Completion',
      amount: 500,
      timestamp: '2h ago'
    },
    {
      title: 'Community Award',
      amount: 250,
      timestamp: '1d ago'
    },
    {
      title: 'Collaboration Bonus',
      amount: 750,
      timestamp: '3d ago'
    }
  ];
</script>

<div class="dream-card">
  <div class="flex items-center justify-between mb-6">
    <h2 class="text-xl font-bold flex items-center">
      <Clock size={20} class="mr-2 text-dream-pink" />
      Recent Rewards
    </h2>
    <button class="text-sm text-dream-pink hover:text-dream-teal transition-colors">
      View All
    </button>
  </div>

  <div class="space-y-4">
    {#each history as item}
      <div class="dream-card bg-dream-surface/50 hover:scale-[1.02] transition-all">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="font-bold">{item.title}</h3>
            <span class="text-sm text-dream-ghost/40">{item.timestamp}</span>
          </div>
          <div class="flex items-center text-dream-yellow">
            <Coins size={16} class="mr-1" />
            {item.amount}
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>