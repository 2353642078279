<script lang="ts">
  import { onMount } from 'svelte';
  import { Shield, Users, Star } from 'lucide-svelte';
  import RealmCard from '$lib/components/Realm/RealmCard.svelte';
  import { realmStore } from '$lib/stores/realms';
  import type { Realm } from '$lib/types';

  let loading = true;

  onMount(async () => {
    await realmStore.loadRealms();
    loading = false;
  });

  $: realms = $realmStore.realms;
</script>

<div class="py-6">
  <div class="flex justify-between items-center mb-8">
    <div>
      <h1 class="text-4xl font-bold mb-2 bg-gradient-to-r from-dream-pink to-dream-teal bg-clip-text text-transparent">
        Realms
      </h1>
      <p class="text-dream-ghost/60">Explore and join creative collaboration spaces</p>
    </div>
    <a href="#/realms/new" class="dream-button">
      Create Realm
    </a>
  </div>

  {#if loading}
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      {#each Array(2) as _}
        <div class="dream-card animate-pulse">
          <div class="h-32 bg-dream-surface/50 rounded-lg mb-4"></div>
          <div class="h-6 bg-dream-surface/50 rounded w-1/3 mb-2"></div>
          <div class="h-4 bg-dream-surface/50 rounded w-2/3"></div>
        </div>
      {/each}
    </div>
  {:else}
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      {#each realms as realm}
        <RealmCard {realm} />
      {/each}
    </div>
  {/if}
</div>