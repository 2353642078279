<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { Globe, Shield, Image, Settings, ChevronRight, Loader2 } from 'lucide-svelte';
  import { crawlWebsite } from '$lib/services/crawler';
  import { createRealm } from '$lib/services/realm';
  import { showSuccess, showError } from '$lib/utils/toast';
  import { push } from 'svelte-spa-router';

  let currentStep = 0;
  let loading = false;
  let websiteUrl = '';
  
  let realmData = {
    name: '',
    description: '',
    longDescription: '',
    themeColor: '#FF3366',
    socialLinks: {
      twitter: '',
      discord: '',
      github: ''
    },
    team: [],
    assets: {
      logo: '',
      banner: ''
    }
  };

  const steps = [
    {
      title: 'Import Project',
      icon: Globe,
      description: 'Let us analyze your project website to get started quickly'
    },
    {
      title: 'Basic Details',
      icon: Shield,
      description: 'Tell us about your project and its vision'
    },
    {
      title: 'Theme & Branding',
      icon: Image,
      description: 'Customize how your realm looks and feels'
    },
    {
      title: 'Rules & Rewards',
      icon: Settings,
      description: 'Set up contribution guidelines and reward mechanisms'
    }
  ];

  async function importFromWebsite() {
    if (!websiteUrl) return;
    
    loading = true;
    try {
      const data = await crawlWebsite(websiteUrl);
      
      if (data) {
        realmData = {
          ...realmData,
          name: data.name,
          description: data.description,
          longDescription: data.longDescription,
          socialLinks: data.socialLinks,
          team: data.team,
          assets: {
            logo: data.assets.find(a => a.type === 'logo')?.url || '',
            banner: data.assets.find(a => a.type === 'banner')?.url || ''
          }
        };
        showSuccess('Project details imported successfully!');
        nextStep();
      }
    } catch (error) {
      showError('Failed to import project details');
    } finally {
      loading = false;
    }
  }

  function isStepComplete(step: number): boolean {
    switch (step) {
      case 0:
        return websiteUrl.length > 0 || (realmData.name && realmData.description);
      case 1:
        return realmData.name.length > 0 && realmData.description.length > 0;
      case 2:
        return realmData.assets.logo.length > 0 || realmData.assets.banner.length > 0;
      case 3:
        return true;
      default:
        return false;
    }
  }

  async function handleSubmit() {
    try {
      // Convert assets object to array format expected by API
      const formattedData = {
        ...realmData,
        assets: [
          { type: 'logo', url: realmData.assets.logo },
          { type: 'banner', url: realmData.assets.banner }
        ]
      };

      const success = await createRealm(formattedData);
      if (success) {
        push('/realms');
      }
    } catch (error) {
      showError('Failed to create realm');
    }
  }

  function nextStep() {
    if (isStepComplete(currentStep) && currentStep < steps.length - 1) {
      currentStep++;
    }
  }

  function prevStep() {
    if (currentStep > 0) {
      currentStep--;
    }
  }
</script>

<!-- Template section remains the same until the assets section -->
<div class="py-6">
  <div class="mb-8">
    <h1 class="text-4xl font-bold mb-2 bg-gradient-to-r from-dream-pink to-dream-teal bg-clip-text text-transparent">
      Create New Realm
    </h1>
    <p class="text-dream-ghost/60">Transform your project into an interactive creative universe</p>
  </div>

  <div class="dream-card">
    <!-- Progress Steps -->
    <div class="mb-8">
      <div class="flex justify-between items-center mb-8">
        {#each steps as step, index}
          <div class="flex-1 relative {index !== steps.length - 1 ? 'after:content-[""] after:absolute after:top-1/2 after:left-full after:w-full after:h-px after:bg-dream-pink/20' : ''}">
            <div class="flex flex-col items-center relative z-10">
              <div class="w-12 h-12 rounded-full bg-dream-surface flex items-center justify-center mb-2 border-2 
                {currentStep === index ? 'border-dream-pink text-dream-pink' : 
                 currentStep > index ? 'border-dream-teal text-dream-teal' : 
                 'border-dream-ghost/20 text-dream-ghost/40'}">
                <svelte:component this={step.icon} size={24} />
              </div>
              <span class="text-sm font-medium text-center
                {currentStep === index ? 'text-dream-pink' : 
                 currentStep > index ? 'text-dream-teal' : 
                 'text-dream-ghost/40'}">
                {step.title}
              </span>
              <span class="text-xs text-dream-ghost/40 text-center mt-1">
                {step.description}
              </span>
            </div>
          </div>
        {/each}
      </div>

      <!-- Step Content -->
      <div class="space-y-6">
        {#if currentStep === 0}
          <div class="space-y-4" in:fade>
            <div>
              <label for="website" class="block text-sm font-medium text-dream-ghost/60 mb-2">Project Website</label>
              <div class="flex space-x-4">
                <input
                  type="url"
                  id="website"
                  bind:value={websiteUrl}
                  class="dream-input flex-1"
                  placeholder="Enter your project's website URL"
                  disabled={loading}
                />
                <button
                  class="dream-button"
                  on:click={importFromWebsite}
                  disabled={!websiteUrl || loading}
                >
                  {#if loading}
                    <Loader2 size={20} class="mr-2 animate-spin" />
                    Importing...
                  {:else}
                    Import Details
                  {/if}
                </button>
              </div>
              <p class="mt-2 text-sm text-dream-ghost/40">
                We'll analyze your website to pre-populate realm details
              </p>
            </div>

            <div class="text-center py-8 text-dream-ghost/60">
              <p>- or -</p>
            </div>

            <button
              class="dream-button w-full"
              on:click={nextStep}
            >
              Enter Details Manually
            </button>
          </div>

        {:else if currentStep === 1}
          <div class="space-y-4" in:fade>
            <div>
              <label for="name" class="block text-sm font-medium text-dream-ghost/60 mb-2">Realm Name</label>
              <input
                type="text"
                id="name"
                bind:value={realmData.name}
                class="dream-input w-full"
                placeholder="Enter realm name"
              />
            </div>
            <div>
              <label for="description" class="block text-sm font-medium text-dream-ghost/60 mb-2">Short Description</label>
              <textarea
                id="description"
                bind:value={realmData.description}
                class="dream-input w-full h-32"
                placeholder="Briefly describe your realm"
              ></textarea>
            </div>
            <div>
              <label for="longDescription" class="block text-sm font-medium text-dream-ghost/60 mb-2">Detailed Description</label>
              <textarea
                id="longDescription"
                bind:value={realmData.longDescription}
                class="dream-input w-full h-48"
                placeholder="Share your project's complete story and vision"
              ></textarea>
            </div>
          </div>

        {:else if currentStep === 2}
          <div class="space-y-4" in:fade>
            <div>
              <label class="block text-sm font-medium text-dream-ghost/60 mb-4">Visual Assets</label>
              
              <div class="mb-4">
                <label for="logo" class="block text-sm text-dream-ghost/60 mb-2">Logo</label>
                <input
                  type="text"
                  id="logo"
                  bind:value={realmData.assets.logo}
                  class="dream-input w-full"
                  placeholder="Enter logo URL"
                />
              </div>

              <div class="mb-4">
                <label for="banner" class="block text-sm text-dream-ghost/60 mb-2">Banner</label>
                <input
                  type="text"
                  id="banner"
                  bind:value={realmData.assets.banner}
                  class="dream-input w-full"
                  placeholder="Enter banner URL"
                />
              </div>
            </div>

            <div>
              <label for="themeColor" class="block text-sm font-medium text-dream-ghost/60 mb-2">Theme Color</label>
              <input
                type="color"
                id="themeColor"
                bind:value={realmData.themeColor}
                class="dream-input w-full h-12"
              />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label for="twitter" class="block text-sm font-medium text-dream-ghost/60 mb-2">Twitter</label>
                <input
                  type="url"
                  id="twitter"
                  bind:value={realmData.socialLinks.twitter}
                  class="dream-input w-full"
                  placeholder="Twitter URL"
                />
              </div>
              <div>
                <label for="discord" class="block text-sm font-medium text-dream-ghost/60 mb-2">Discord</label>
                <input
                  type="url"
                  id="discord"
                  bind:value={realmData.socialLinks.discord}
                  class="dream-input w-full"
                  placeholder="Discord URL"
                />
              </div>
              <div>
                <label for="github" class="block text-sm font-medium text-dream-ghost/60 mb-2">GitHub</label>
                <input
                  type="url"
                  id="github"
                  bind:value={realmData.socialLinks.github}
                  class="dream-input w-full"
                  placeholder="GitHub URL"
                />
              </div>
            </div>
          </div>

        {:else if currentStep === 3}
          <div class="space-y-4" in:fade>
            <div>
              <label for="rules" class="block text-sm font-medium text-dream-ghost/60 mb-2">Contribution Rules</label>
              <textarea
                id="rules"
                bind:value={realmData.contributionRules}
                class="dream-input w-full h-32"
                placeholder="Define contribution guidelines"
              ></textarea>
            </div>
            <div>
              <label for="token" class="block text-sm font-medium text-dream-ghost/60 mb-2">Reward Token</label>
              <input
                type="text"
                id="token"
                bind:value={realmData.rewardToken}
                class="dream-input w-full"
                placeholder="Token contract address"
              />
            </div>
            <div>
              <label for="stake" class="block text-sm font-medium text-dream-ghost/60 mb-2">Minimum Stake</label>
              <input
                type="number"
                id="stake"
                bind:value={realmData.minStake}
                class="dream-input w-full"
                placeholder="Minimum token stake required"
              />
            </div>
          </div>
        {/if}

        <!-- Navigation Buttons -->
        <div class="flex justify-between pt-6">
          {#if currentStep > 0}
            <button
              class="dream-button bg-dream-surface"
              on:click={prevStep}
            >
              Back
            </button>
          {:else}
            <div></div>
          {/if}

          {#if currentStep === steps.length - 1}
            <button
              class="dream-button"
              on:click={handleSubmit}
              disabled={!isStepComplete(currentStep)}
            >
              Create Realm
            </button>
          {:else}
            <button
              class="dream-button"
              on:click={nextStep}
              disabled={!isStepComplete(currentStep)}
            >
              Next
              <ChevronRight size={20} class="ml-2" />
            </button>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>