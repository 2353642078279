<script lang="ts">
  import { Trophy, Target, Coins } from 'lucide-svelte';

  const stats = [
    {
      label: 'Models Created',
      value: 12,
      icon: Trophy,
      color: 'text-dream-pink'
    },
    {
      label: 'Total Uses',
      value: 1458,
      icon: Target,
      color: 'text-dream-teal'
    },
    {
      label: 'DREAMS Earned',
      value: '2,450',
      icon: Coins,
      color: 'text-dream-yellow'
    }
  ];
</script>

<div class="dream-card">
  <h2 class="text-xl font-bold mb-6">Creator Stats</h2>

  <div class="space-y-4">
    {#each stats as stat}
      <div class="dream-card bg-dream-surface/50">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-3">
            <div class="p-2 rounded-lg bg-dream-surface/30 {stat.color}">
              <svelte:component this={stat.icon} size={20} />
            </div>
            <span class="text-dream-ghost/60">{stat.label}</span>
          </div>
          <span class="font-bold">{stat.value}</span>
        </div>
      </div>
    {/each}
  </div>
</div>