<script lang="ts">
  import { Star, Trophy } from 'lucide-svelte';

  const achievements = [
    {
      title: 'Creative Pioneer',
      description: 'Complete your first collaboration',
      progress: 80,
      reward: 500
    },
    {
      title: 'Rising Star',
      description: 'Receive 100 upvotes',
      progress: 45,
      reward: 1000
    },
    {
      title: 'Community Leader',
      description: 'Help 10 new creators',
      progress: 30,
      reward: 750
    }
  ];
</script>

<div class="dream-card">
  <div class="flex items-center justify-between mb-6">
    <h2 class="text-xl font-bold flex items-center">
      <Trophy size={20} class="mr-2 text-dream-pink" />
      Achievements
    </h2>
    <span class="text-dream-ghost/40 text-sm">3 in progress</span>
  </div>

  <div class="space-y-4">
    {#each achievements as achievement}
      <div class="dream-card bg-dream-surface/50">
        <div class="flex justify-between items-start mb-2">
          <div>
            <h3 class="font-bold">{achievement.title}</h3>
            <p class="text-sm text-dream-ghost/60">{achievement.description}</p>
          </div>
          <div class="flex items-center text-dream-yellow text-sm">
            <Star size={16} class="mr-1" />
            {achievement.reward}
          </div>
        </div>
        <div class="h-2 bg-dream-surface rounded-full overflow-hidden">
          <div
            class="h-full bg-gradient-to-r from-dream-pink to-dream-teal transition-all"
            style="width: {achievement.progress}%"
          ></div>
        </div>
      </div>
    {/each}
  </div>
</div>