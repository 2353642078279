<script lang="ts">
  import { fade, scale } from 'svelte/transition';
  import { Star, Clock, Users, Award } from 'lucide-svelte';
  import { createEventDispatcher } from 'svelte';
  import type { Mentor } from '$lib/types/mentorship';

  const dispatch = createEventDispatcher();

  export let mentor: Mentor;

  function handleRequestMentor() {
    dispatch('requestMentor', mentor);
  }
</script>

<div 
  class="dream-card group hover:scale-105 transition-all duration-300"
  in:scale={{ duration: 300 }}
>
  <div class="flex items-start space-x-4">
    <img
      src={mentor.avatar}
      alt={mentor.name}
      class="w-16 h-16 rounded-lg bg-dream-surface"
    />
    <div class="flex-1">
      <div class="flex items-center justify-between">
        <h3 class="font-bold group-hover:text-dream-pink transition-colors">
          {mentor.name}
        </h3>
        <div class="flex items-center text-dream-yellow">
          <Star size={16} class="mr-1" />
          <span>{mentor.rating}</span>
        </div>
      </div>
      
      <p class="text-sm text-dream-ghost/60 mb-4">{mentor.bio}</p>
      
      <div class="flex items-center space-x-4 text-sm text-dream-ghost/40">
        <div class="flex items-center">
          <Clock size={16} class="mr-1" />
          <span>{mentor.availability}</span>
        </div>
        <div class="flex items-center">
          <Users size={16} class="mr-1" />
          <span>{mentor.apprenticeCount} apprentices</span>
        </div>
        <div class="flex items-center">
          <Award size={16} class="mr-1" />
          <span>{mentor.successfulMentorships} completed</span>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 pt-4 border-t border-dream-pink/10">
    <div class="flex flex-wrap gap-2 mb-4">
      {#each mentor.skills as skill}
        <span class="px-2 py-1 text-xs rounded-full bg-dream-surface text-dream-ghost/60">
          {skill}
        </span>
      {/each}
    </div>

    <button 
      class="dream-button w-full"
      on:click={handleRequestMentor}
    >
      Request Mentorship
    </button>
  </div>
</div>