<script lang="ts">
  import { Shield, Users, Star } from 'lucide-svelte';
  import { push } from 'svelte-spa-router';
  import type { Realm } from '../../types';

  export let realm: Realm;

  function navigateToRealm() {
    push(`/realms/${realm.id}`);
  }

  // Special styling for Mindborn Sons
  $: isMindborn = realm.id === 'mindborn';
</script>

<div class="dream-card group hover:translate-y-[-2px]">
  <div class="flex items-start space-x-4">
    <!-- Logo/Image -->
    <div class="w-32 h-16 rounded-lg bg-dream-surface overflow-hidden">
      <img
        src={realm.logo || realm.image}
        alt={realm.name}
        class="w-full h-full object-contain"
      />
    </div>

    <div class="flex-1">
      <h3 class="text-xl font-space mb-1 group-hover:text-dream-pink transition-colors">
        {realm.name}
      </h3>
      <p class="text-dream-ghost/60 text-sm line-clamp-2">{realm.description}</p>
    </div>
  </div>

  <div class="mt-4 pt-4 border-t border-[var(--dream-border)] flex items-center justify-between text-sm">
    <div class="flex space-x-4 text-dream-ghost/60">
      <div class="flex items-center">
        <Users size={16} class="mr-1" />
        <span>{realm.memberCount}</span>
      </div>
      <div class="flex items-center">
        <Shield size={16} class="mr-1" />
        <span>{realm.bountyCount}</span>
      </div>
      <div class="flex items-center">
        <Star size={16} class="mr-1" />
        <span>{realm.rating}</span>
      </div>
    </div>
    <button
      on:click={navigateToRealm}
      class="dream-button py-1 px-4 text-sm hover:scale-105 transition-all duration-300"
    >
      Enter →
    </button>
  </div>
</div>