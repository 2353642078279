<script lang="ts">
  import { Target, ArrowUp } from 'lucide-svelte';

  const stats = {
    weeklyGrowth: '+25%',
    totalEarned: '12,450',
    reputation: 92,
    level: 15
  };
</script>

<div class="dream-card">
  <div class="flex items-center justify-between mb-6">
    <h2 class="text-xl font-bold flex items-center">
      <Target size={20} class="mr-2 text-dream-pink" />
      Creator Stats
    </h2>
  </div>

  <div class="space-y-4">
    <div class="dream-card bg-dream-surface/50">
      <div class="flex items-center justify-between">
        <span class="text-dream-ghost/60">Weekly Growth</span>
        <div class="flex items-center text-dream-teal">
          <ArrowUp size={16} class="mr-1" />
          {stats.weeklyGrowth}
        </div>
      </div>
    </div>

    <div class="dream-card bg-dream-surface/50">
      <div class="flex items-center justify-between">
        <span class="text-dream-ghost/60">Total Earned</span>
        <span class="font-bold">{stats.totalEarned} DREAMS</span>
      </div>
    </div>

    <div class="dream-card bg-dream-surface/50">
      <div class="flex items-center justify-between">
        <span class="text-dream-ghost/60">Reputation</span>
        <span class="font-bold">{stats.reputation}/100</span>
      </div>
    </div>
  </div>
</div>