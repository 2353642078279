<script lang="ts">
  export let size: 'sm' | 'md' | 'lg' = 'md';
  export let variant: 'default' | 'white' | 'text' | 'turquoise' | 'icon' = 'default';
  export let className = '';

  const sizes = {
    sm: 'h-8',
    md: 'h-12',
    lg: 'h-16'
  };

  const logos = {
    default: 'https://i.imgur.com/LeRQG3Q.png',   // Blue logo
    white: 'https://i.imgur.com/yBfsuDs.png',     // White logo
    text: 'https://i.imgur.com/6yYZPzL.png',      // Text logo
    turquoise: 'https://i.imgur.com/rrofYSp.png', // Turquoise text logo
    icon: 'https://i.imgur.com/ZnWmEh4.png'       // Icon logo
  };
</script>

<img 
  src={logos[variant]} 
  alt="DreamDeck" 
  class="{sizes[size]} {className} object-contain"
/>