<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { Heart, Share2, Award, Twitter, MessageSquare } from 'lucide-svelte';
  import type { GeneratedArt } from '$lib/types/art';
  import { artStore } from '$lib/stores/art';
  import { authStore } from '$lib/stores/auth';

  export let realmId: string;
  
  $: art = $artStore.realmArt.filter(a => a.realmId === realmId);

  async function handleVote(artId: string) {
    await artStore.voteArt(artId);
  }

  async function handleShare(artId: string, platform: 'twitter' | 'discord' | 'instagram') {
    await artStore.shareToSocial(artId, platform);
  }

  function formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
</script>

<div class="space-y-8">
  {#each art as piece (piece.id)}
    <div 
      class="dream-card group hover:scale-[1.01] transition-all duration-300"
      in:fade={{ duration: 300 }}
    >
      <!-- Art Image -->
      <img
        src={piece.url}
        alt={piece.prompt}
        class="w-full rounded-lg mb-4"
      />

      <!-- Info -->
      <div class="flex justify-between items-start mb-4">
        <div>
          <p class="text-sm text-dream-ghost/60 mb-2">
            Created {formatTimestamp(piece.timestamp)}
          </p>
          <p class="text-dream-ghost/80">{piece.prompt}</p>
        </div>
        
        <!-- Status Badge -->
        {#if piece.status === 'approved'}
          <div class="px-3 py-1 rounded-full bg-dream-teal/20 text-dream-teal text-sm flex items-center">
            <Award size={16} class="mr-1" />
            Approved
          </div>
        {:else if piece.status === 'pending'}
          <div class="px-3 py-1 rounded-full bg-dream-yellow/20 text-dream-yellow text-sm">
            {10 - piece.votes} votes to approve
          </div>
        {/if}
      </div>

      <!-- Actions -->
      <div class="flex items-center justify-between pt-4 border-t border-dream-pink/10">
        <!-- Vote Button -->
        <button
          class="flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-dream-surface transition-colors
            {piece.votes > 0 ? 'text-dream-pink' : 'text-dream-ghost/60'}"
          on:click={() => handleVote(piece.id)}
        >
          <Heart size={20} />
          <span>{piece.votes}</span>
        </button>

        <!-- Share Options -->
        {#if piece.status === 'approved'}
          <div class="flex items-center space-x-2">
            <button
              class="dream-button bg-[#1DA1F2] flex items-center space-x-2"
              on:click={() => handleShare(piece.id, 'twitter')}
            >
              <Twitter size={16} />
              <span>Share</span>
            </button>
            <button
              class="dream-button bg-[#5865F2] flex items-center space-x-2"
              on:click={() => handleShare(piece.id, 'discord')}
            >
              <MessageSquare size={16} />
              <span>Share</span>
            </button>
          </div>
        {/if}
      </div>

      <!-- Rewards Info -->
      {#if piece.status === 'approved'}
        <div class="mt-4 pt-4 border-t border-dream-pink/10">
          <div class="flex items-center justify-between text-sm">
            <span class="text-dream-ghost/60">Creator Reward</span>
            <span class="text-dream-yellow">{piece.rewards.creator} DREAMS</span>
          </div>
          <div class="flex items-center justify-between text-sm mt-2">
            <span class="text-dream-ghost/60">Share Reward</span>
            <span class="text-dream-yellow">{piece.rewards.sharer} DREAMS per share</span>
          </div>
        </div>
      {/if}
    </div>
  {/each}

  {#if art.length === 0}
    <div class="dream-card bg-dream-surface/50 text-center py-12">
      <p class="text-dream-ghost/60 mb-2">No artwork shared yet</p>
      <p class="text-sm text-dream-ghost/40">
        Generate some art and share it with the realm!
      </p>
    </div>
  {/if}
</div>