<script lang="ts">
  import { 
    Home, 
    Trophy,
    Target, 
    User, 
    Settings, 
    HelpCircle,
    LogOut,
    ChevronLeft,
    ChevronRight,
    Sparkles,
    Book,
    Store,
    Blocks
  } from 'lucide-svelte';
  import { push, location } from 'svelte-spa-router';
  import { _ } from 'svelte-i18n';
  import { authStore } from '../../stores/auth';
  import { writable } from 'svelte/store';
  import Logo from '../ui/Logo.svelte';

  const isCollapsed = writable(true);

  const menuItems = [
    { icon: Home, label: 'Dashboard', path: '/dashboard' },
    { icon: Sparkles, label: 'Creation Hub', path: '/creation-hub' },
    { icon: Book, label: 'Skills & Mentoring', path: '/skills' },
    { icon: Store, label: 'Marketplace', path: '/marketplace' },
    { icon: Trophy, label: 'Rewards', path: '/rewards' },
    { icon: Blocks, label: 'Test Arbitrum', path: '/test/arbitrum' }
  ];

  const bottomMenuItems = [
    { icon: HelpCircle, label: 'Help', path: '/help' },
    { icon: User, label: 'Profile', path: '/profile' },
    { icon: Settings, label: 'Settings', path: '/settings' }
  ];

  function navigate(path: string) {
    push(path);
  }

  function handleLogout() {
    authStore.logout();
  }

  function toggleSidebar() {
    isCollapsed.update(v => !v);
  }

  // Track current path for active state
  $: currentPath = $location;

  function isActive(path: string): boolean {
    return currentPath === path || currentPath.startsWith(path + '/');
  }
</script>

<aside class="fixed left-0 top-0 h-screen {$isCollapsed ? 'w-20' : 'w-64'} bg-surface border-r border-turquoise/10 transition-all duration-300 z-50 flex flex-col">
  <!-- Logo and Toggle -->
  <div class="p-6 border-b border-turquoise/10 flex items-center justify-between">
    {#if !$isCollapsed}
      <Logo variant="icon" size="sm" />
    {:else}
      <Logo variant="icon" size="sm" />
    {/if}
    <button 
      class="p-2 rounded-lg hover:bg-surface-light transition-colors {$isCollapsed ? 'absolute -right-4 top-6 bg-surface border border-turquoise/10' : ''}"
      on:click={toggleSidebar}
    >
      <svelte:component this={$isCollapsed ? ChevronRight : ChevronLeft} size={16} class="text-turquoise" />
    </button>
  </div>

  <!-- Main Menu -->
  <div class="flex-1 overflow-y-auto p-4">
    <div class="mb-8">
      {#each menuItems as item}
        <button
          class="w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors
            {isActive(item.path) ? 
              'text-turquoise bg-turquoise/10 font-medium border border-turquoise/20' : 
              'text-white/60 hover:text-white hover:bg-surface-light'}"
          on:click={() => navigate(item.path)}
        >
          <svelte:component 
            this={item.icon} 
            size={20} 
            class={isActive(item.path) ? 'text-turquoise' : ''} 
          />
          {#if !$isCollapsed}
            <span>{item.label}</span>
          {/if}
        </button>
      {/each}
    </div>

    <div>
      {#each bottomMenuItems as item}
        <button
          class="w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors
            {isActive(item.path) ? 
              'text-turquoise bg-turquoise/10 font-medium border border-turquoise/20' : 
              'text-white/60 hover:text-white hover:bg-surface-light'}"
          on:click={() => navigate(item.path)}
        >
          <svelte:component 
            this={item.icon} 
            size={20} 
            class={isActive(item.path) ? 'text-turquoise' : ''} 
          />
          {#if !$isCollapsed}
            <span>{item.label}</span>
          {/if}
        </button>
      {/each}

      <button
        class="w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors text-white/60 hover:text-white hover:bg-surface-light mt-4"
        on:click={handleLogout}
      >
        <LogOut size={20} />
        {#if !$isCollapsed}
          <span>Logout</span>
        {/if}
      </button>
    </div>
  </div>
</aside>

<style>
  aside {
    flex-shrink: 0;
  }
</style>