<script lang="ts">
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { getProfile } from '$lib/services/auth';
  import { push } from 'svelte-spa-router';
  import ProfileCard from '$lib/components/Profile/ProfileCard.svelte';

  let profile: any = null;
  let loading = true;
  let error: string | null = null;

  onMount(async () => {
    try {
      profile = await getProfile();
      if (!profile) {
        push('/');
      }
    } catch (err: any) {
      error = err.message;
    } finally {
      loading = false;
    }
  });
</script>

<div class="min-h-screen bg-cod-gray p-6">
  {#if loading}
    <div class="dream-card animate-pulse">
      <div class="h-32 bg-surface/50 rounded-lg mb-4"></div>
      <div class="h-8 bg-surface/50 rounded w-1/3 mb-2"></div>
      <div class="h-4 bg-surface/50 rounded w-2/3"></div>
    </div>
  {:else if error}
    <div class="dream-card bg-red-500/10 text-center py-12">
      <p class="text-red-500 mb-4">{error}</p>
      <button class="dream-button" on:click={() => push('/')}>
        Return to Home
      </button>
    </div>
  {:else if profile}
    <ProfileCard {profile} />
  {/if}
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>