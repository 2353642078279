import { writable } from 'svelte/store';
import type { Skill, SkillProgress } from '../types/skills';

interface SkillState {
  skills: Skill[];
  progress: SkillProgress[];
  loading: boolean;
  error: string | null;
}

function createSkillStore() {
  const { subscribe, set, update } = writable<SkillState>({
    skills: [],
    progress: [],
    loading: false,
    error: null
  });

  return {
    subscribe,
    
    loadSkills: async () => {
      update(state => ({ ...state, loading: true, error: null }));
      
      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const mockSkills: Skill[] = [
          {
            id: 'combat-basics',
            name: 'Combat Basics',
            description: 'Learn fundamental combat mechanics',
            type: 'combat',
            level: 1,
            xpRequired: 100
          },
          {
            id: 'character-design',
            name: 'Character Design',
            description: 'Master character creation and customization',
            type: 'design',
            level: 2,
            xpRequired: 200,
            prerequisites: ['combat-basics']
          }
        ];

        update(state => ({
          ...state,
          skills: mockSkills,
          loading: false
        }));
      } catch (error: any) {
        update(state => ({
          ...state,
          error: error.message || 'Failed to load skills',
          loading: false
        }));
      }
    },

    updateProgress: async (skillId: string, xp: number) => {
      update(state => {
        const progress = state.progress.find(p => p.skillId === skillId);
        
        if (progress) {
          progress.currentXp += xp;
          progress.lastUpdated = new Date().toISOString();
        } else {
          state.progress.push({
            skillId,
            currentXp: xp,
            level: 1,
            achievements: [],
            lastUpdated: new Date().toISOString()
          });
        }

        return { ...state };
      });
    }
  };
}

export const skillStore = createSkillStore();