<script lang="ts">
  import { fade } from 'svelte/transition';
  import type { Skill } from '$lib/types/skills';
  import SkillTreeNode from './SkillTreeNode.svelte';
  import SkillDetails from './SkillDetails.svelte';
  import { generateLearningContent } from '$lib/services/skillLearning';

  export let skills: Skill[] = [];
  export let unlockedSkills: string[] = [];

  let selectedSkill: Skill | null = null;
  let showDetails = false;
  let learningContent = null;

  const categories = [
    { id: 'combat', name: 'Combat', description: 'Master combat mechanics and systems' },
    { id: 'design', name: 'Design', description: 'Create stunning visuals and assets' },
    { id: 'lore', name: 'Lore', description: 'Craft compelling stories and worlds' },
    { id: 'technical', name: 'Technical', description: 'Build game systems and tools' }
  ];

  $: categorizedSkills = categories.map(category => ({
    ...category,
    skills: skills.filter(skill => skill.type === category.id)
  }));

  function isSkillUnlocked(skill: Skill): boolean {
    if (!skill.prerequisites?.length) return true;
    return skill.prerequisites.every(prereq => unlockedSkills.includes(prereq));
  }

  async function handleSkillSelect(event: CustomEvent<Skill>) {
    selectedSkill = event.detail;
    showDetails = true;
    learningContent = await generateLearningContent(selectedSkill);
  }

  function closeDetails() {
    showDetails = false;
    selectedSkill = null;
    learningContent = null;
  }
</script>

<div class="space-y-8">
  {#each categorizedSkills as category}
    {#if category.skills.length > 0}
      <div class="dream-card" in:fade>
        <div class="flex items-center justify-between mb-6">
          <div>
            <h2 class="text-xl font-bold text-turquoise">{category.name} Skills</h2>
            <p class="text-sm text-dream-ghost/60">{category.description}</p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {#each category.skills as skill}
            <SkillTreeNode
              {skill}
              isUnlocked={isSkillUnlocked(skill)}
              on:select={handleSkillSelect}
            />
          {/each}
        </div>
      </div>
    {/if}
  {/each}
</div>

{#if showDetails && selectedSkill}
  <SkillDetails 
    skill={selectedSkill}
    {learningContent}
    on:close={closeDetails}
  />
{/if}

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }
</style>