<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Trophy, Target, Star } from 'lucide-svelte';
  import type { UserProfile } from '$lib/types/user';

  export let profile: UserProfile;

  $: experienceToNextLevel = (profile.level + 1) * 1000 - profile.experience_points;
  $: experienceProgress = (profile.experience_points % 1000) / 10;
</script>

<div class="dream-card" in:fade>
  <div class="flex items-center space-x-6">
    <img
      src={profile.avatar_url}
      alt={profile.username}
      class="w-24 h-24 rounded-lg bg-surface border-2 border-turquoise/20"
    />
    <div>
      <h1 class="text-3xl font-bold mb-2 bg-gradient-to-r from-turquoise to-turquoise/50 bg-clip-text text-transparent">
        {profile.username}
      </h1>
      <p class="text-dream-ghost/60">{profile.bio}</p>
    </div>
  </div>

  <div class="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
    <div class="dream-card bg-surface/50">
      <div class="flex items-center space-x-3">
        <Trophy class="text-turquoise" size={24} />
        <div>
          <p class="text-sm text-dream-ghost/60">Level</p>
          <p class="text-2xl font-bold font-space">{profile.level}</p>
        </div>
      </div>
    </div>

    <div class="dream-card bg-surface/50">
      <div class="flex items-center space-x-3">
        <Target class="text-turquoise" size={24} />
        <div>
          <p class="text-sm text-dream-ghost/60">XP</p>
          <p class="text-2xl font-bold font-space">{profile.experience_points}</p>
        </div>
      </div>
    </div>

    <div class="dream-card bg-surface/50">
      <div class="flex items-center space-x-3">
        <Star class="text-turquoise" size={24} />
        <div>
          <p class="text-sm text-dream-ghost/60">Reputation</p>
          <p class="text-2xl font-bold font-space">{profile.reputation_score}</p>
        </div>
      </div>
    </div>
  </div>

  {#if profile.skills?.length > 0}
    <div class="mt-6">
      <h2 class="text-xl font-bold mb-4">Skills</h2>
      <div class="flex flex-wrap gap-2">
        {#each profile.skills as skill}
          <span class="px-3 py-1 rounded-full bg-surface text-dream-ghost/60">
            {skill}
          </span>
        {/each}
      </div>
    </div>
  {/if}
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }
</style>