import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'
import { arbitrumSepolia } from 'viem/chains'
import { ethers } from 'ethers';
import { authStore } from '../stores/auth';
import { 
  getAccount, 
  getWalletClient, 
  getPublicClient 
} from '@wagmi/core'

// Types
export class Web3Error extends Error {
  constructor(message: string, public code?: number) {
    super(message);
    this.name = 'Web3Error';
  }
}

export interface Web3Provider {
  provider: ethers.BrowserProvider;
  signer: ethers.JsonRpcSigner;
  address: string;
}

// Environment variables
const WALLETCONNECT_PROJECT_ID = import.meta.env.VITE_WALLETCONNECT_PROJECT_ID;
const ARBITRUM_RPC_URL = import.meta.env.VITE_ARBITRUM_RPC_URL;

if (!WALLETCONNECT_PROJECT_ID || !ARBITRUM_RPC_URL) {
  throw new Error('Missing required environment variables');
}

// Configure Web3Modal
const chains = [arbitrumSepolia];
const projectId = WALLETCONNECT_PROJECT_ID;

const metadata = {
  name: 'Dreamdeck',
  description: 'Dreamdeck Wallet Login',
  url: typeof window !== 'undefined' ? window.location.origin : 'https://dreamdeck.app',
  icons: ['https://dreamdeck.app/favicon.png']
}

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

const web3modal = createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-accent': '#12EBFF',
    '--w3m-background-color': '#0B0D0C',
    '--w3m-text-medium-regular': '#94A3B8',
  },
  defaultChain: arbitrumSepolia,
})

// Wallet connection functions
export const connectWallet = async (): Promise<Web3Provider> => {
  try {
    const account = await web3modal.open();
    
    // Wait a moment for the connection to stabilize
    await new Promise(resolve => setTimeout(resolve, 500));

    const walletClient = await getWalletClient();
    const publicClient = await getPublicClient();

    if (!walletClient) {
      throw new Web3Error('No wallet client available', 4001);
    }

    const [address] = await walletClient.getAddresses();
    const provider = new ethers.BrowserProvider(walletClient as any);
    const signer = await provider.getSigner();

    const session = {
      provider,
      signer,
      address
    };

    authStore.login(session);
    return session;
  } catch (error: any) {
    console.error('Error connecting wallet:', error);
    if (error.code === 4001) {
      throw new Web3Error('User rejected connection', 4001);
    }
    throw new Web3Error(error.message || 'Failed to connect wallet', error.code);
  }
};

export const disconnectWallet = async () => {
  try {
    await web3modal.close();
    authStore.logout();
  } catch (error: any) {
    console.error('Error disconnecting wallet:', error);
    throw new Web3Error(error.message || 'Failed to disconnect wallet', error.code);
  }
};

export const isWalletConnected = async (): Promise<boolean> => {
  try {
    const account = getAccount();
    
    if (account.isConnected && account.address) {
      const walletClient = await getWalletClient();
      
      if (!walletClient) return false;

      const [address] = await walletClient.getAddresses();
      const provider = new ethers.BrowserProvider(walletClient as any);
      const signer = await provider.getSigner();

      const session = {
        provider,
        signer,
        address
      };

      authStore.login(session);
      return true;
    }

    return false;
  } catch (error) {
    console.error('Error checking wallet connection:', error);
    return false;
  }
};

// Utility functions
export const signMessage = async (message: string, signer: ethers.JsonRpcSigner): Promise<string> => {
  try {
    return await signer.signMessage(message);
  } catch (error: any) {
    console.error('Error signing message:', error);
    throw new Web3Error(error.message || 'Failed to sign message', error.code);
  }
};

export const getBalance = async (address: string, provider: ethers.BrowserProvider): Promise<string> => {
  try {
    const balance = await provider.getBalance(address);
    return ethers.formatEther(balance);
  } catch (error: any) {
    console.error('Error getting balance:', error);
    throw new Web3Error(error.message || 'Failed to get balance', error.code);
  }
};

export const formatAddress = (address: string): string => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};
