import { showError, showSuccess } from '../utils/toast';
import type { ProjectData } from './realm';
import openai from './openai';

export async function crawlWebsite(url: string): Promise<ProjectData | null> {
  try {
    // First try to fetch the website content
    const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`);
    if (!response.ok) throw new Error('Failed to fetch website content');
    
    const data = await response.json();
    const html = data.contents;

    // Use GPT-4 to analyze the HTML content
    const gptResponse = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: `You are a web content analyzer specialized in extracting project information.
                   Analyze the provided HTML content and extract key information in JSON format.
                   Include:
                   - name: Project name from title or h1
                   - description: Short description from meta or first paragraph
                   - longDescription: Detailed description from about section
                   - socialLinks: Object with social media URLs
                   - team: Array of {name, role} objects
                   - assets: Array of {type: "logo"|"banner", url: string}`
        },
        {
          role: "user",
          content: `Extract project information from this HTML: ${html}`
        }
      ],
      temperature: 0.5,
      max_tokens: 2000
    });

    const content = gptResponse.choices[0].message?.content;
    if (!content) throw new Error('No content generated');

    // Parse the JSON response
    const extractedData = JSON.parse(content);

    // Create project data object
    const projectData: ProjectData = {
      name: extractedData.name || 'New Project',
      description: extractedData.description || 'A brief description of your project',
      longDescription: extractedData.longDescription || 'A detailed description of your project\'s vision and goals...',
      tags: extractedData.tags || ['creative', 'web3', 'gaming'],
      socialLinks: extractedData.socialLinks || {},
      team: extractedData.team || [{ name: 'Project Lead', role: 'Creator' }],
      assets: extractedData.assets || []
    };

    showSuccess('Website analysis completed successfully!');
    return projectData;
  } catch (error: any) {
    console.error('Web analysis error:', error);
    showError(error?.message || 'Failed to analyze website. Please enter details manually.');
    return null;
  }
}