<script lang="ts">
  import { fade } from 'svelte/transition';
  import { ChevronLeft, ChevronRight, Users } from 'lucide-svelte';
  import { push } from 'svelte-spa-router';

  let currentIndex = 0;
  let autoplayInterval: NodeJS.Timeout;

  const realms = [
    {
      id: 'mindborn',
      name: 'MINDBORN SONS',
      tagline: 'A THIRD-PERSON PVP BATTLE ARENA',
      description: 'Choose a tribe. Create your warrior. Join the battle in an epic world.',
      banner: 'https://i.imgur.com/gVLkPh0.png',
      logo: 'https://i.imgur.com/aKWyPta.png',
      members: 156,
      activity: 'High',
      opportunities: [
        'Character Artists Needed',
        'Combat System Designers',
        'Lore Writers'
      ]
    }
  ];

  function nextSlide() {
    currentIndex = (currentIndex + 1) % realms.length;
  }

  function prevSlide() {
    currentIndex = (currentIndex - 1 + realms.length) % realms.length;
  }

  function goToRealm(id: string) {
    push(`/realms/${id}`);
  }

  function startAutoplay() {
    autoplayInterval = setInterval(nextSlide, 4000);
  }

  function stopAutoplay() {
    if (autoplayInterval) {
      clearInterval(autoplayInterval);
    }
  }

  // Start autoplay on mount
  startAutoplay();

  // Cleanup on destroy
  import { onDestroy } from 'svelte';
  onDestroy(() => {
    stopAutoplay();
  });
</script>

<div 
  class="relative h-[400px] rounded-xl overflow-hidden" 
  on:mouseenter={stopAutoplay}
  on:mouseleave={startAutoplay}
>
  <!-- Slides -->
  <div 
    class="flex h-full transition-transform duration-700" 
    style="transform: translateX(-{currentIndex * 100}%)"
  >
    {#each realms as realm}
      <div 
        class="w-full flex-shrink-0 relative h-full cursor-pointer"
        role="button"
        tabindex="0"
        on:click={() => goToRealm(realm.id)}
        on:keydown={(e) => e.key === 'Enter' && goToRealm(realm.id)}
      >
        <!-- Background Image -->
        <img
          src={realm.banner}
          alt={realm.name}
          class="absolute inset-0 w-full h-full object-cover"
        />
        
        <!-- Gradient Overlays -->
        <div class="absolute inset-0 bg-gradient-to-t from-cod-gray via-cod-gray/40 to-transparent"></div>
        <div class="absolute inset-0 bg-gradient-to-r from-cod-gray/90 via-transparent to-transparent"></div>

        <!-- Content -->
        <div class="relative h-full flex flex-col justify-end p-8">
          <div class="max-w-3xl">
            <h3 class="text-5xl font-space font-bold mb-4 text-white">
              {realm.tagline}
            </h3>
            
            <p class="text-xl text-white/90 mb-6">
              {realm.description}
            </p>
            
            <!-- Opportunities Tags -->
            <div class="flex flex-wrap gap-3 mb-6">
              {#each realm.opportunities as opportunity}
                <span class="px-4 py-2 rounded-full bg-surface/30 backdrop-blur-sm text-white/90 text-sm">
                  {opportunity}
                </span>
              {/each}
            </div>

            <!-- Logo and Stats -->
            <div class="flex items-center justify-between">
              <img 
                src={realm.logo}
                alt={realm.name}
                class="h-12 object-contain"
              />
              
              <div class="flex items-center space-x-6 text-white/80">
                <div class="flex items-center space-x-2">
                  <Users size={16} />
                  <span>{realm.members} members</span>
                </div>
                <div class="flex items-center space-x-2">
                  <div class="w-2 h-2 rounded-full bg-turquoise"></div>
                  <span>{realm.activity} Activity</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/each}
  </div>

  <!-- Navigation Buttons -->
  <button
    class="absolute left-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-surface/80 hover:bg-surface backdrop-blur-sm transition-colors"
    on:click|stopPropagation={prevSlide}
    aria-label="Previous slide"
  >
    <ChevronLeft size={20} />
  </button>
  
  <button
    class="absolute right-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-surface/80 hover:bg-surface backdrop-blur-sm transition-colors"
    on:click|stopPropagation={nextSlide}
    aria-label="Next slide"
  >
    <ChevronRight size={20} />
  </button>

  <!-- Dots -->
  <div class="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
    {#each realms as _, i}
      <button
        class="w-2 h-2 rounded-full transition-colors {i === currentIndex ? 'bg-turquoise' : 'bg-white/40'}"
        on:click|stopPropagation={() => currentIndex = i}
        aria-label="Go to slide {i + 1}"
        aria-current={i === currentIndex}
      ></button>
    {/each}
  </div>
</div>

<style>
  /* Add text shadow for better readability */
  h3, p {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }
</style>