<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Book, Users, Trophy, Target, Star, Shield } from 'lucide-svelte';
  import { skillStore } from '$lib/stores/skills';
  import { mentorshipStore } from '$lib/stores/mentorship';
  import SkillTree from '$lib/components/Skills/SkillTree.svelte';
  import MentorCard from '$lib/components/Mentorship/MentorCard.svelte';
  import { onMount } from 'svelte';

  onMount(() => {
    skillStore.loadSkills();
    mentorshipStore.loadMentors();
  });

  $: skills = $skillStore.skills;
  $: mentors = $mentorshipStore.mentors;
  $: loading = $skillStore.loading || $mentorshipStore.loading;

  let activeTab = 'skills';

  const stats = [
    {
      label: 'Current Level',
      value: '15',
      icon: Trophy,
      color: 'text-turquoise'
    },
    {
      label: 'Skills Unlocked',
      value: '8',
      icon: Target,
      color: 'text-turquoise'
    },
    {
      label: 'Achievements',
      value: '12',
      icon: Star,
      color: 'text-turquoise'
    },
    {
      label: 'Active Mentors',
      value: '3',
      icon: Shield,
      color: 'text-turquoise'
    }
  ];
</script>

<div class="min-h-screen bg-cod-gray p-6 space-y-6">
  <!-- Header -->
  <div class="dream-card">
    <h1 class="text-3xl font-bold mb-2">Skills & Mentoring</h1>
    <p class="text-dream-ghost/60">Level up your skills and connect with mentors</p>
  </div>

  <!-- Stats Grid -->
  <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
    {#each stats as stat}
      <div class="dream-card group hover:scale-105 transition-all duration-300">
        <div class="flex items-center justify-between mb-2">
          <svelte:component 
            this={stat.icon} 
            size={24} 
            class="{stat.color} transform group-hover:scale-110 transition-transform duration-300" 
          />
          <span class="text-dream-ghost/40 text-sm">{stat.label}</span>
        </div>
        <div class="text-2xl font-space font-bold group-hover:text-turquoise transition-colors">
          {stat.value}
        </div>
      </div>
    {/each}
  </div>

  <!-- Navigation Tabs -->
  <div class="dream-card">
    <div class="flex space-x-8 border-b border-turquoise/10">
      <button
        class="py-4 px-6 border-b-2 transition-colors relative group
          {activeTab === 'skills' ? 'border-turquoise text-turquoise' : 'border-transparent text-white/40'}"
        on:click={() => activeTab = 'skills'}
      >
        <div class="flex items-center space-x-2">
          <Book size={20} />
          <span>Skill Tree</span>
        </div>
        {#if activeTab === 'skills'}
          <div class="absolute inset-0 bg-turquoise/5 rounded-t-lg -z-10" in:fade></div>
        {/if}
      </button>

      <button
        class="py-4 px-6 border-b-2 transition-colors relative group
          {activeTab === 'mentors' ? 'border-turquoise text-turquoise' : 'border-transparent text-white/40'}"
        on:click={() => activeTab = 'mentors'}
      >
        <div class="flex items-center space-x-2">
          <Users size={20} />
          <span>Find Mentors</span>
        </div>
        {#if activeTab === 'mentors'}
          <div class="absolute inset-0 bg-turquoise/5 rounded-t-lg -z-10" in:fade></div>
        {/if}
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="dream-card min-h-[600px]">
    {#if loading}
      <div class="animate-pulse space-y-4">
        <div class="h-8 bg-surface-light rounded w-1/3"></div>
        <div class="h-4 bg-surface-light rounded w-2/3"></div>
        <div class="h-4 bg-surface-light rounded w-1/2"></div>
      </div>
    {:else if activeTab === 'skills'}
      <div in:fade>
        <SkillTree {skills} unlockedSkills={['combat-basics']} />
      </div>
    {:else if activeTab === 'mentors'}
      <div class="space-y-6" in:fade>
        {#each mentors as mentor}
          <MentorCard {mentor} />
        {/each}
      </div>
    {/if}
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>