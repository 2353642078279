<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import { Code, Palette, Book, Sword, X, Trophy, Target, Loader2 } from 'lucide-svelte';
  import type { Skill } from '$lib/types/skills';
  import type { LearningContent } from '$lib/services/skillLearning';

  const dispatch = createEventDispatcher();
  
  export let skill: Skill;
  export let learningContent: LearningContent | null = null;

  const skillIcons = {
    combat: Sword,
    design: Palette,
    lore: Book,
    technical: Code
  };

  const skillColors = {
    combat: 'text-turquoise',
    design: 'text-turquoise',
    lore: 'text-turquoise',
    technical: 'text-turquoise'
  };

  let activeTab = 'overview';

  function handleClose() {
    dispatch('close');
  }

  function startLearning() {
    // Implement learning path start logic
  }
</script>

<div 
  class="fixed inset-0 bg-cod-gray/80 backdrop-blur-sm z-50 flex items-center justify-center p-4"
  in:fade={{ duration: 200 }}
  on:click={handleClose}
>
  <div 
    class="dream-card max-w-4xl w-full max-h-[80vh] overflow-hidden flex flex-col"
    in:fly={{ y: 20, duration: 300 }}
    on:click|stopPropagation
  >
    <!-- Header -->
    <div class="flex items-start justify-between mb-6">
      <div class="flex items-center space-x-4">
        <div class="p-4 rounded-lg bg-surface/50 {skillColors[skill.type]}">
          <svelte:component this={skillIcons[skill.type]} size={32} />
        </div>
        <div>
          <h2 class="text-2xl font-bold mb-1">{skill.name}</h2>
          <p class="text-dream-ghost/60">Level {skill.level} Skill</p>
        </div>
      </div>
      <button 
        class="p-2 hover:bg-surface/60 rounded-lg transition-colors"
        on:click={handleClose}
      >
        <X size={24} />
      </button>
    </div>

    <!-- Tabs -->
    <div class="flex space-x-4 mb-6 border-b border-turquoise/10">
      <button
        class="px-4 py-2 {activeTab === 'overview' ? 'text-turquoise border-b-2 border-turquoise' : 'text-dream-ghost/60'}"
        on:click={() => activeTab = 'overview'}
      >
        Overview
      </button>
      <button
        class="px-4 py-2 {activeTab === 'learning' ? 'text-turquoise border-b-2 border-turquoise' : 'text-dream-ghost/60'}"
        on:click={() => activeTab = 'learning'}
      >
        Learning Path
      </button>
    </div>

    <!-- Content -->
    <div class="flex-1 overflow-y-auto">
      {#if activeTab === 'overview'}
        <div class="space-y-6">
          <p class="text-dream-ghost/80">{skill.description}</p>

          <!-- Stats -->
          <div class="grid grid-cols-2 gap-4">
            <div class="dream-card bg-surface/50">
              <div class="flex items-center space-x-2 text-turquoise">
                <Trophy size={20} />
                <span>Level {skill.level}</span>
              </div>
            </div>
            <div class="dream-card bg-surface/50">
              <div class="flex items-center space-x-2 text-turquoise">
                <Target size={20} />
                <span>{skill.xpRequired} XP Required</span>
              </div>
            </div>
          </div>

          <!-- Prerequisites -->
          {#if skill.prerequisites?.length}
            <div>
              <h3 class="text-lg font-bold mb-4">Prerequisites</h3>
              <div class="flex flex-wrap gap-2">
                {#each skill.prerequisites as prereq}
                  <div class="px-3 py-1 rounded-full bg-surface text-dream-ghost/60 text-sm">
                    {prereq}
                  </div>
                {/each}
              </div>
            </div>
          {/if}
        </div>
      {:else if activeTab === 'learning'}
        {#if !learningContent}
          <div class="flex flex-col items-center justify-center py-12 text-dream-ghost/60">
            <Loader2 size={32} class="animate-spin mb-4" />
            <p>Generating personalized learning content...</p>
          </div>
        {:else}
          <div class="space-y-6">
            <!-- Syllabus -->
            <div>
              <h3 class="text-lg font-bold mb-4">Learning Path</h3>
              <div class="space-y-4">
                {#each learningContent.syllabus as topic, i}
                  <div class="dream-card bg-surface/50">
                    <div class="flex items-start">
                      <span class="w-8 h-8 rounded-full bg-turquoise/10 text-turquoise flex items-center justify-center mr-4">
                        {i + 1}
                      </span>
                      <p>{topic}</p>
                    </div>
                  </div>
                {/each}
              </div>
            </div>

            <!-- Resources -->
            <div>
              <h3 class="text-lg font-bold mb-4">Learning Resources</h3>
              <div class="space-y-2">
                {#each learningContent.resources as resource}
                  <div class="dream-card bg-surface/50">
                    <p>{resource}</p>
                  </div>
                {/each}
              </div>
            </div>
          </div>
        {/if}
      {/if}
    </div>

    <!-- Action Button -->
    <div class="flex justify-end mt-6 pt-6 border-t border-turquoise/10">
      <button 
        class="dream-button"
        on:click={startLearning}
      >
        Start Learning Path
      </button>
    </div>
  </div>
</div>

<style>
  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>