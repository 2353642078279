import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/database';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  console.warn('Supabase environment variables not found. Some features may be unavailable.');
}

export const supabase = createClient<Database>(
  supabaseUrl || '',
  supabaseKey || '',
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: false
    },
    db: {
      schema: 'public'
    }
  }
);

// Test database connection
export async function testConnection(): Promise<boolean> {
  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('count')
      .limit(1);

    if (error) throw error;
    return true;
  } catch (error: any) {
    console.error('Connection test failed:', error);
    return false;
  }
}

// Initialize database tables
export async function setupDatabase(): Promise<boolean> {
  let retries = 3;
  let success = false;

  while (retries > 0 && !success) {
    try {
      // Create tables
      const { error: userError } = await supabase.rpc('setup_user_tables');
      if (userError) throw userError;

      const { error: realmError } = await supabase.rpc('setup_realm_tables');
      if (realmError) throw realmError;

      const { error: storageError } = await supabase.rpc('setup_storage_tables');
      if (storageError) throw storageError;

      success = true;
      return true;
    } catch (error: any) {
      console.error(`Database setup attempt ${4 - retries} failed:`, error);
      retries--;
      
      if (retries === 0) {
        return false;
      }
      
      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  return success;
}