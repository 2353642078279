import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import ja from './locales/ja.json';

// Add message dictionaries
addMessages('en', en);
addMessages('es', es);
addMessages('fr', fr);
addMessages('ja', ja);

// Initialize svelte-i18n
init({
  fallbackLocale: 'en',
  initialLocale: localStorage.getItem('preferred-language') || getLocaleFromNavigator()
});