import { writable } from 'svelte/store';
import type { GeneratedArt } from '../types/art';
import { showSuccess, showError } from '../utils/toast';
import { authStore } from './auth';

interface ArtState {
  realmArt: GeneratedArt[];
  loading: boolean;
  error: string | null;
}

function createArtStore() {
  const { subscribe, set, update } = writable<ArtState>({
    realmArt: [],
    loading: false,
    error: null
  });

  return {
    subscribe,

    shareToRealm: async (url: string, prompt: string, realmId: string) => {
      update(state => ({ ...state, loading: true }));

      try {
        const newArt: GeneratedArt = {
          id: Date.now().toString(),
          url,
          prompt,
          creator: authStore.getState()?.address || '',
          realmId,
          timestamp: new Date().toISOString(),
          votes: 0,
          status: 'pending',
          shares: 0,
          rewards: {
            creator: 100, // Base reward for creation
            sharer: 25 // Reward per share
          }
        };

        update(state => ({
          ...state,
          realmArt: [newArt, ...state.realmArt],
          loading: false
        }));

        showSuccess('Art shared to realm successfully!');
        return newArt;
      } catch (error: any) {
        showError(error.message || 'Failed to share art');
        update(state => ({ ...state, loading: false }));
        return null;
      }
    },

    voteArt: async (artId: string) => {
      update(state => {
        const art = state.realmArt.find(a => a.id === artId);
        if (!art) return state;

        art.votes += 1;
        
        // Auto-approve at 10 votes
        if (art.votes >= 10 && art.status === 'pending') {
          art.status = 'approved';
          showSuccess('Art approved for social sharing!');
        }

        return {
          ...state,
          realmArt: [...state.realmArt]
        };
      });
    },

    shareToSocial: async (artId: string, platform: 'twitter' | 'discord' | 'instagram') => {
      update(state => {
        const art = state.realmArt.find(a => a.id === artId);
        if (!art || art.status !== 'approved') return state;

        art.shares += 1;
        
        // Reward the sharer
        // In production, this would be handled by a smart contract
        showSuccess(`Earned ${art.rewards.sharer} DREAMS for sharing!`);

        return {
          ...state,
          realmArt: [...state.realmArt]
        };
      });
    }
  };
}

export const artStore = createArtStore();