<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { MessageSquare, Book, ArrowRight, Save, History, Loader2, ChevronRight } from 'lucide-svelte';
  import { generateStoryContent, saveDraft, getDrafts, type StoryResponse, type StoryDraft } from '$lib/services/story';
  import type { Bounty } from '$lib/types';
  import { showError } from '$lib/utils/toast';
  import { onMount } from 'svelte';

  export let bounty: Bounty | null = null;

  let prompt = '';
  let content = '';
  let loading = false;
  let drafts: StoryDraft[] = [];
  let currentResponse: StoryResponse | null = null;
  let savingDraft = false;
  let showDrafts = false;

  async function loadDrafts() {
    if (!bounty) return;
    try {
      drafts = await getDrafts(bounty.id);
    } catch (error) {
      // Error handling is done in the service
      drafts = [];
    }
  }

  async function handleGenerate() {
    if (!bounty || !prompt) return;
    
    loading = true;
    try {
      currentResponse = await generateStoryContent(prompt, bounty);
      content = currentResponse.content;
    } catch (error) {
      // Error handling is done in the service
    } finally {
      loading = false;
    }
  }

  async function handleSaveDraft() {
    if (!bounty || !content || savingDraft) return;
    
    savingDraft = true;
    try {
      await saveDraft({
        bounty_id: bounty.id,
        content,
        word_count: content.split(/\s+/).length,
        status: 'draft'
      });
      await loadDrafts();
    } catch (error) {
      // Error handling is done in the service
    } finally {
      savingDraft = false;
    }
  }

  function loadDraft(draft: StoryDraft) {
    content = draft.content;
    showDrafts = false;
  }

  // Load drafts when bounty changes
  $: if (bounty) {
    loadDrafts();
  }
</script>

{#if !bounty}
  <div class="dream-card bg-dream-surface/50 text-center py-12">
    <MessageSquare size={48} class="mx-auto mb-4 text-dream-pink" />
    <p class="text-dream-ghost/60">Select a story bounty to begin writing</p>
  </div>
{:else}
  <div class="space-y-6" in:fade>
    <!-- Bounty Details -->
    <div class="dream-card bg-dream-surface/50">
      <h2 class="text-xl font-bold mb-4">{bounty.title}</h2>
      <p class="text-dream-ghost/60 mb-6">{bounty.description}</p>
      
      <div class="space-y-2">
        <h3 class="font-bold text-sm text-dream-ghost/40">Requirements:</h3>
        <ul class="list-disc list-inside text-dream-ghost/60 space-y-1">
          {#each bounty.requirements as req}
            <li>{req}</li>
          {/each}
        </ul>
      </div>
    </div>

    <!-- Writing Interface -->
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <!-- Left Column: Editor -->
      <div class="space-y-4">
        <div class="dream-card bg-dream-surface/50">
          <div class="flex items-center justify-between mb-4">
            <h3 class="font-bold">Story Editor</h3>
            <div class="flex space-x-2">
              <button
                class="dream-button bg-dream-surface"
                on:click={() => showDrafts = !showDrafts}
              >
                <History size={20} class="mr-2" />
                Drafts ({drafts.length})
              </button>
              <button
                class="dream-button"
                on:click={handleSaveDraft}
                disabled={!content || savingDraft}
              >
                {#if savingDraft}
                  <Loader2 size={20} class="mr-2 animate-spin" />
                  Saving...
                {:else}
                  <Save size={20} class="mr-2" />
                  Save Draft
                {/if}
              </button>
            </div>
          </div>

          <div class="space-y-4">
            <div>
              <input
                type="text"
                bind:value={prompt}
                placeholder="Enter a prompt to generate content..."
                class="dream-input w-full"
              />
            </div>

            <div class="flex justify-end">
              <button
                class="dream-button"
                on:click={handleGenerate}
                disabled={!prompt || loading}
              >
                {#if loading}
                  <Loader2 size={20} class="mr-2 animate-spin" />
                  Generating...
                {:else}
                  <MessageSquare size={20} class="mr-2" />
                  Generate
                {/if}
              </button>
            </div>

            <textarea
              bind:value={content}
              class="dream-input w-full h-[400px] font-mono"
              placeholder="Your story will appear here..."
            ></textarea>
          </div>
        </div>
      </div>

      <!-- Right Column: References & Drafts -->
      <div class="space-y-6">
        {#if showDrafts}
          <div class="dream-card bg-dream-surface/50">
            <h3 class="font-bold mb-4">Saved Drafts</h3>
            {#if drafts.length === 0}
              <p class="text-dream-ghost/60">No drafts saved yet</p>
            {:else}
              <div class="space-y-4">
                {#each drafts as draft}
                  <button
                    class="dream-card bg-dream-surface/30 w-full text-left hover:scale-[1.02] transition-all"
                    on:click={() => loadDraft(draft)}
                  >
                    <div class="flex items-center justify-between">
                      <div>
                        <p class="text-sm text-dream-ghost/60">
                          {new Date(draft.created_at).toLocaleDateString()} - {draft.word_count} words
                        </p>
                        <p class="text-sm mt-1">
                          {draft.content.slice(0, 100)}...
                        </p>
                      </div>
                      <ChevronRight size={20} class="text-dream-ghost/40" />
                    </div>
                  </button>
                {/each}
              </div>
            {/if}
          </div>
        {/if}

        {#if currentResponse?.loreReferences}
          <div class="dream-card bg-dream-surface/50">
            <h3 class="font-bold mb-4">Lore References</h3>
            <div class="space-y-4">
              {#each currentResponse.loreReferences as ref}
                <div class="dream-card bg-dream-surface/30">
                  <h4 class="font-bold mb-1">{ref.title}</h4>
                  <p class="text-sm text-dream-ghost/60">{ref.description}</p>
                </div>
              {/each}
            </div>
          </div>
        {/if}

        {#if currentResponse?.suggestions}
          <div class="dream-card bg-dream-surface/50">
            <h3 class="font-bold mb-4">Writing Suggestions</h3>
            <div class="space-y-2">
              {#each currentResponse.suggestions as suggestion}
                <div class="flex items-start space-x-2 text-dream-ghost/60">
                  <ArrowRight size={16} class="mt-1 flex-shrink-0" />
                  <p class="text-sm">{suggestion}</p>
                </div>
              {/each}
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}

<style>
  textarea {
    font-family: 'Space Mono', monospace;
    line-height: 1.6;
    resize: vertical;
  }
</style>