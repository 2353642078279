import { wrap } from 'svelte-spa-router/wrap';
import Landing from '../routes/+page.svelte';
import Dashboard from '../routes/dashboard/+page.svelte';
import Skills from '../routes/skills/+page.svelte';
import Realms from '../routes/realms/+page.svelte';
import RealmDetails from '../routes/realms/[id]/+page.svelte';
import NewRealm from '../routes/realms/new/+page.svelte';
import Marketplace from '../routes/marketplace/+page.svelte';
import Rewards from '../routes/rewards/+page.svelte';
import Profile from '../routes/profile/+page.svelte';
import Settings from '../routes/settings/+page.svelte';
import CreationHub from '../routes/creation-hub/+page.svelte';
import ArtGenerator from '../routes/art-generator/+page.svelte';
import Wiki from '../routes/wiki/+page.svelte';
import NotFound from '../routes/NotFound.svelte';
import ArbitrumTest from '../routes/test/arbitrum/+page.svelte';
import { authStore } from './stores/auth';

// Navigation guard for protected routes
const protectedRoute = (component: any) => wrap({
  component,
  conditions: [
    () => {
      const state = authStore.getState();
      if (!state) {
        window.location.hash = '/';
        return false;
      }
      return true;
    }
  ]
});

export const routes = {
  // Public routes
  '/': Landing,
  '/wiki': Wiki,
  '/wiki/:slug': wrap({
    component: import('../routes/wiki/[slug]/+page.svelte')
  }),

  // Protected routes
  '/dashboard': protectedRoute(Dashboard),
  '/skills': protectedRoute(Skills),
  '/realms': protectedRoute(Realms),
  '/realms/new': protectedRoute(NewRealm),
  '/realms/:id': protectedRoute(RealmDetails),
  '/marketplace': protectedRoute(Marketplace),
  '/rewards': protectedRoute(Rewards),
  '/profile': protectedRoute(Profile),
  '/settings': protectedRoute(Settings),
  '/creation-hub': protectedRoute(CreationHub),
  '/art-generator': protectedRoute(ArtGenerator),
  '/test/arbitrum': protectedRoute(ArbitrumTest),

  // Catch-all route for 404
  '*': NotFound
};