<script lang="ts">
  import { cn } from '../../../utils/cn';
  export let className = '';
</script>

<div class={cn(
  "absolute inset-0 w-full h-full pointer-events-none",
  className
)}>
  <!-- Dot Pattern -->
  <div class="absolute inset-0 w-full h-full bg-dot-black/[0.2] dark:bg-dot-white/[0.2]" />
</div>

<div class="relative z-10 min-h-full w-full max-w-full">
  <slot />
</div>

<style>
  :global(body) {
    overflow-x: hidden;
  }
</style>