<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Trophy, Crown, Users, ThumbsUp, Sparkles, Coins } from 'lucide-svelte';
  import LeaderboardCard from '$lib/components/Rewards/LeaderboardCard.svelte';
  import AchievementTracker from '$lib/components/Rewards/AchievementTracker.svelte';
  import RewardsHistory from '$lib/components/Rewards/RewardsHistory.svelte';
  import CreatorStats from '$lib/components/Rewards/CreatorStats.svelte';

  let selectedTimeframe = 'weekly';
  const timeframes = ['daily', 'weekly', 'monthly', 'all'];

  const personalStats = {
    earnings: '2,450',
    rank: 24,
    upvotes: 156,
    followers: 89
  };

  const leaderboards = [
    {
      title: 'Top Earners',
      icon: Coins,
      color: 'text-turquoise',
      metric: 'DREAMS',
      leaders: [
        { name: 'CryptoArtist', value: '12,450', change: '+15%' },
        { name: 'PixelMaster', value: '10,280', change: '+12%' },
        { name: 'GameWizard', value: '8,920', change: '+8%' }
      ]
    },
    {
      title: 'Most Upvoted',
      icon: ThumbsUp,
      color: 'text-turquoise',
      metric: 'votes',
      leaders: [
        { name: 'DesignGuru', value: '2,845', change: '+25%' },
        { name: 'ArtLegend', value: '2,156', change: '+18%' },
        { name: 'CreativeOne', value: '1,923', change: '+10%' }
      ]
    },
    {
      title: 'Top Collaborators',
      icon: Users,
      color: 'text-turquoise',
      metric: 'collabs',
      leaders: [
        { name: 'TeamPlayer', value: '45', change: '+5' },
        { name: 'Connector', value: '38', change: '+3' },
        { name: 'BuilderPro', value: '32', change: '+4' }
      ]
    }
  ];
</script>

<div class="min-h-screen bg-cod-gray">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <!-- Header -->
    <div class="card mb-8">
      <h1 class="text-4xl font-bold mb-2">Creator Rewards</h1>
      <p class="text-white/60">Track your achievements and earnings</p>
    </div>

    <!-- Stats Grid -->
    <div class="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8" in:fade>
      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <Coins size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Earnings</span>
        </div>
        <div class="text-2xl font-bold">{personalStats.earnings} DREAMS</div>
      </div>

      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <Trophy size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Rank</span>
        </div>
        <div class="text-2xl font-bold">#{personalStats.rank}</div>
      </div>

      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <ThumbsUp size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Upvotes</span>
        </div>
        <div class="text-2xl font-bold">{personalStats.upvotes}</div>
      </div>

      <div class="card card-hover">
        <div class="flex items-center justify-between mb-2">
          <Users size={24} class="text-turquoise" />
          <span class="text-white/60 text-sm">Followers</span>
        </div>
        <div class="text-2xl font-bold">{personalStats.followers}</div>
      </div>
    </div>

    <!-- Main Content Grid -->
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <!-- Leaderboards -->
      <div class="lg:col-span-2 space-y-6">
        {#each leaderboards as board}
          <LeaderboardCard {board} />
        {/each}
      </div>

      <!-- Right Column -->
      <div class="space-y-8">
        <!-- Achievement Tracker -->
        <AchievementTracker />
        
        <!-- Rewards History -->
        <RewardsHistory />
        
        <!-- Creator Stats -->
        <CreatorStats />
      </div>
    </div>
  </div>
</div>