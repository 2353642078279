<script lang="ts">
  import { fade } from 'svelte/transition';
  import { Shield, Users, Target, Activity } from 'lucide-svelte';
  import BentoGrid from '$lib/components/ui/BentoGrid.svelte';
  import BentoGridItem from '$lib/components/ui/BentoGridItem.svelte';
  import RealmCarousel from '$lib/components/Dashboard/RealmCarousel.svelte';
  import ActivityFeed from '$lib/components/Dashboard/ActivityFeed.svelte';
  import CreatorStats from '$lib/components/Dashboard/CreatorStats.svelte';

  const stats = [
    {
      label: 'Active Realms',
      value: 3,
      icon: Shield,
      color: 'text-turquoise'
    },
    {
      label: 'Contributors',
      value: 156,
      icon: Users,
      color: 'text-turquoise'
    },
    {
      label: 'Open Bounties',
      value: 24,
      icon: Target,
      color: 'text-turquoise'
    },
    {
      label: 'Activity Score',
      value: '92%',
      icon: Activity,
      color: 'text-turquoise'
    }
  ];
</script>

<div class="min-h-screen bg-cod-gray p-6">
  <!-- Welcome Message -->
  <div class="dream-card mb-6">
    <h1 class="text-2xl font-bold text-turquoise">Welcome back to your creative hub</h1>
  </div>

  <!-- Stats Grid -->
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
    {#each stats as stat}
      <div class="dream-card group hover:scale-105 transition-all duration-300">
        <div class="flex items-center justify-between mb-2">
          <svelte:component 
            this={stat.icon} 
            size={24} 
            class="{stat.color} transform group-hover:scale-110 transition-transform duration-300" 
          />
          <span class="text-dream-ghost/40 text-sm">{stat.label}</span>
        </div>
        <div class="text-2xl font-space font-bold group-hover:text-turquoise transition-colors">
          {stat.value}
        </div>
      </div>
    {/each}
  </div>

  <!-- Main Content Grid -->
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
    <!-- Left Column -->
    <div class="lg:col-span-2 space-y-6">
      <!-- Featured Realms -->
      <div class="dream-card">
        <h2 class="text-xl font-bold mb-6">Featured Realms</h2>
        <RealmCarousel />
      </div>

      <!-- Creator Profile -->
      <div class="dream-card">
        <div class="flex items-center justify-between mb-6">
          <h2 class="text-xl font-bold">Creator Profile</h2>
          <button class="dream-button">View Full Profile</button>
        </div>
        <CreatorStats />
      </div>
    </div>

    <!-- Right Column -->
    <div class="space-y-6">
      <!-- Activity Feed -->
      <div class="dream-card">
        <h2 class="text-xl font-bold mb-6">Activity Feed</h2>
        <ActivityFeed />
      </div>

      <!-- Quick Actions -->
      <div class="dream-card">
        <h2 class="text-xl font-bold mb-4">Quick Actions</h2>
        <div class="space-y-3">
          <button class="dream-button w-full justify-start">
            <Target size={20} class="mr-2" />
            Create New Bounty
          </button>
          <button class="dream-button w-full justify-start bg-surface">
            <Users size={20} class="mr-2" />
            Invite Members
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .dream-button {
    @apply inline-flex items-center justify-center px-4 py-2 
           rounded-lg font-medium transition-all duration-300
           bg-turquoise text-cod-gray
           hover:shadow-glow hover:scale-[1.02]
           disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .dream-card {
    @apply bg-surface/95 backdrop-blur-md border border-turquoise/10 rounded-lg p-6
           shadow-glass transition-all duration-300
           hover:shadow-glass-hover hover:border-turquoise/20
           relative overflow-hidden;
  }

  .shadow-glass {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }

  .shadow-glass-hover {
    box-shadow: 0 8px 32px rgba(18, 235, 255, 0.2);
  }

  .shadow-glow {
    box-shadow: 0 0 20px rgba(18, 235, 255, 0.2);
  }
</style>