import { writable, get } from 'svelte/store';
import type { Realm } from '../types';
import { showSuccess, showError } from '../utils/toast';
import { supabase } from '../services/supabase';

interface RealmState {
  realms: Realm[];
  loading: boolean;
  error: string | null;
}

function createRealmStore() {
  const { subscribe, set, update } = writable<RealmState>({
    realms: [
      {
        id: 'mindborn',
        name: 'MINDBORN SONS',
        description: 'An epic tale of conquest, ancient powers, and the destiny of the Seven Sages in a world where metal warriors and mythical beings clash.',
        memberCount: 156,
        bountyCount: 24,
        rating: 4.9,
        image: 'https://i.imgur.com/cwK1f0j.png',
        logo: 'https://i.imgur.com/aKWyPta.png'
      }
    ],
    loading: false,
    error: null
  });

  return {
    subscribe,
    
    loadRealms: async () => {
      update(state => ({ ...state, loading: true, error: null }));
      
      try {
        // For now, just return the current state since we have hardcoded data
        // In the future, this would fetch from Supabase
        update(state => ({ ...state, loading: false }));
      } catch (error: any) {
        showError(error.message || 'Failed to load realms');
        update(state => ({ ...state, loading: false }));
      }
    },

    getRealm: (id: string): Realm | null => {
      const state = get(realmStore);
      return state.realms.find(realm => realm.id === id) || null;
    },

    createRealm: async (data: any) => {
      update(state => ({ ...state, loading: true }));

      try {
        // Create new realm object
        const newRealm: Realm = {
          id: data.name.toLowerCase().replace(/\s+/g, '-'),
          name: data.name,
          description: data.description,
          memberCount: 0,
          bountyCount: 0,
          rating: 0,
          image: data.assets.find((a: any) => a.type === 'banner')?.url || '',
          logo: data.assets.find((a: any) => a.type === 'logo')?.url || ''
        };

        // Add to store
        update(state => ({
          ...state,
          realms: [...state.realms, newRealm],
          loading: false
        }));

        showSuccess('Realm created successfully!');
        return newRealm;
      } catch (error: any) {
        showError(error.message || 'Failed to create realm');
        update(state => ({ ...state, loading: false }));
        return null;
      }
    }
  };
}

export const realmStore = createRealmStore();